import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/digitalmarketing/service1.png'
import service2 from '../../../assets/digitalmarketing/service2.png'
import service3 from '../../../assets/digitalmarketing/service3.png'

const DigitalMarketingServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Our Digital Marketing Service
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    BrainBinary Infotech, a leading digital marketing services agency in the United States, offers a comprehensive suite of digital marketing techniques to help you develop and grow your internet business and promote your products so you can reach out to potential consumers more efficiently.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        SEO Services Content
                        </div>
                        <div className={style.contentfont}>
                        Search engine optimization is the process of improving your website traffic through organic search and helping growing your business connect with the targeting audience. Brain Binary Infotech offers the best SEO services to boost traffic and enhance the rankings on stated keywords. That helps you drive customers to your website.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Social Media Marketing Services
                        </div>
                        <div className={style.contentfont}>
                        Social Media Marketing is the best way to promote your business in the world. Brain Binary Infotech is a social media marketing company in India, USA, and helps clients promote their products & services on Social media platforms like Facebook, Instagram, Twitter, Pinterest to get more customers to boost sales and conversions.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        PPC Services
                        </div>
                        <div className={style.contentfont}>
                        Pay Per Click is an online advertising model, and process of improving the visibility of your business website in search engines to drive huge traffic and boost sales. Our experts create the most engaging ads that help your business drive more clicks and increase sales. Contact our expert team now and get a free estimation.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalMarketingServices