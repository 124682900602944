import React from 'react'
import style from './itconsulting.module.css'
import roundspring from '../../assets/roundspring.png'
import ContactUs from '../../components/contactus/ContactUs'
import itconsulting1 from '../../assets/itconsulting/itconsultingimage1.png'
import itconsulting2 from '../../assets/itconsulting/itconsultingimage2.png'
import GetFreeConsultancy from '../../components/getfreeconsultancy/GetFreeConsultancy'
import Experience from '../../components/expireance/Experience'
import WordsFromClientele from '../../components/wordsfromClientele/WordsFromClientele'
import ConsultingServices from '../../components/services/it-consulting/IsoServices'
import ItAppModernization from '../../components/numberbackground/itappmodernization/ItAppModernization'
import WhyBBItConsulting from '../../components/whybbitconsulting/WhyBBItConsulting'
import IndustryServe from '../../components/industryserve/IndustryServe'

const ItConsulting = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.development}>IT </span>
                            <span className={style.software}>Consulting </span>
                            <span className={style.company}>Services</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Utilize the knowledge and extensive technical experience of the leading experts at Brain Binary Infotech to create a detailed IT strategy for the digital and technological transformation of your business. We are an established IT Consulting Company that provides different types of IT consulting services to automate and improve the performance of your business.
                                </p>
                            </div>
                            <div>
                                <a href='#getfreeconsultancy' className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`}>Book a Free Consultation </a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" className='h-100 w-100' />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-md-5 mt-3 text-end d-xl-inline d-flex justify-content-center position-relative">
                        <img src={itconsulting1} className={style.consultingimage} alt="Web Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowpadding}`}>
                    <div>
                        <h1 className={`${style.heading} ${style.headingborder}`}>How Can IT Consulting Services Help<br /> Your Business?    </h1>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <div className='mt-md-4 mt-2'>
                                <p className={style.content}>In this competitive world, technical innovations like cloud, cognitive, and digital have an impact on the businesses of different sectors like Automotive, industrial, consumer goods, life sciences, retail, and travel. Our team of IT experts is ready for the challenges to quickly develop and implement innovative solutions as per the requirements of your business.
                                </p>
                                <p className={style.content}>Brain Binary Infotech’s Information technology consultants provide the services which are essential for customers to effectively control their IT problems.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 ps-lg-5 ps-3 text-end position-relative">
                        <img src={itconsulting2} className={style.consultingimage} alt="Web Devlopment" />
                    </div>
                </div>
                <ConsultingServices />
                <ItAppModernization />
                <GetFreeConsultancy />
                <Experience />
                <WhyBBItConsulting />
                <IndustryServe />
                <WordsFromClientele />
                <ContactUs />
            </div >
        </>
    )
}

export default ItConsulting