import React from 'react'
import style from './whybbitconsulting.module.css'
import service1 from '../../assets/itconsulting/whybbservice1.png'
import service2 from '../../assets/itconsulting/whybbservice2.png'
import service3 from '../../assets/itconsulting/whybbservice3.png'
import service4 from '../../assets/itconsulting/whybbservice4.png'
import service5 from '../../assets/itconsulting/whybbservice5.png'

const WhyBBItConsulting = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Why Choose Brain Binary Infotech for IT Consulting Service
                </div>
                <div className='d-flex justify-content-center pb-lg-4'>
                    <h1 className={style.content}>
                        Connect with an IT consultant that handles your company’s most sensitive information and resources for the growth of your business and it’s not easy to trust anyone. That’s why choose Brain Binary Infotech as we have been providing the finest IT Consulting Services for different types of businesses. Our consultants use the greatest technical resources to deliver the best results.
                    </h1>
                </div>
                <div className='d-flex justify-content-center mt-xl-5 mt-3'>
                    <div className={style.boxleft}>
                        <div className={style.roundleft}><img src={service1}  alt="On-Time Delivery" /></div>
                        <div className=''>
                            <h1 className={style.boxheading}>On-Time Delivery </h1>
                            <p className={style.boxcontent}>We have been providing consulting services before the deadline and we make sure that it doesn’t affect the quality of work.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-xl-5 mt-3'>
                    <div className={style.boxright}>
                        <div className={style.roundright}><img src={service2} alt="On-Time Delivery" /></div>
                        <div className='w-100 text-end'>
                            <h1 className={style.boxheading}>Cost-Effective Solutions </h1>
                            <p className={style.boxcontent}>At Brain Binary Infotech, we believe in providing cost-effective solutions for clients so that even startups can afford our services.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-xl-5 mt-3'>
                    <div className={style.boxleft}>
                        <div className={style.roundleft}><img src={service3} alt="On-Time Delivery" /></div>
                        <div className=''>
                            <h1 className={style.boxheading}>One-to-one Communication </h1>
                            <p className={style.boxcontent}>We provide the facility of one-to-one communication so that clients can talk easily with our consultants through Skype or Call.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-xl-5 mt-3'>
                    <div className={style.boxright}>
                        <div className={style.roundright}><img src={service4} alt="On-Time Delivery" /></div>
                        <div className='w-100 text-end'>
                            <h1 className={style.boxheading}>100% Transparency </h1>
                            <p className={style.boxcontent}>We believe in transparency and that’s why our consultants always send updated reports to the clients regularly so they can point out any changes if they want.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-xl-5 mt-3'>
                    <div className={style.boxleft}>
                        <div className={style.roundleft}><img src={service5} alt="On-Time Delivery" /></div>
                        <div className=''>
                            <h1 className={style.boxheading}>Engagement Models </h1>
                            <p className={style.boxcontent}>We provide different types of engagement models and you can choose the best engagement model to get IT consultation services for your business.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyBBItConsulting