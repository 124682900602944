import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/flutter/flutterimage1.png'
import service2 from '../../../assets/flutter/flutterimage2.png'
import service3 from '../../../assets/flutter/flutterimage3.png'
import service4 from '../../../assets/flutter/flutterimage4.png'
import service5 from '../../../assets/flutter/flutterimage5.png'
import service6 from '../../../assets/flutter/flutterimage6.png'
import service7 from '../../../assets/flutter/flutterimage7.png'
import service8 from '../../../assets/flutter/flutterimage8.png'
import service9 from '../../../assets/flutter/flutterimage9.png'

const IsoServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our IOS Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Hire iPhone developers from Brain Binanry Infotech if you are trying to get high-performance native or cross-platform iOS solutions.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Custom iPhone App Development
                        </div>
                        <div className={style.contentfont}>
                            Hire remote iOS developers who focus on performance and functionality to offer customized user experience no matter whether you need enterprise solutions or a niche-specific app.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            iPhone App Strategy
                        </div>
                        <div className={style.contentfont}>
                            We have a team of experts who begin with robust iPhone app strategies that include defining features to outline the functionalities to build the best iOS app.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            iPhone App Design
                        </div>
                        <div className={style.contentfont}>
                            Our iPhone developers have a deep understanding of Apple’s design principles which they follow closely to create intuitive navigation and engaging interfaces for the app.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            iPhone App Testing
                        </div>
                        <div className={style.contentfont}>
                            Hire skilled iOS developers who use the latest testing tools and methodologies for functional testing and performance testing to ensure the quality of the iOS app.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Support and Maintenance
                        </div>
                        <div className={style.contentfont}>
                            Get in touch with our dedicated support team who is ready to provide regular updates according to the latest version of iOS to ensure that the app is working properly.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            App Migration
                        </div>
                        <div className={style.contentfont}>
                            Hire iPhone/iOS app developers from us who create the best migration plan to smoothly upgrade the iOS app to the latest iOS version or migrate it from another platform.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Apple Watch App Development
                        </div>
                        <div className={style.contentfont}>
                            We have top Android developers who can easily port your existing app to android so that your new application can get the attention of maximum users.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            iOS Debugging & Testing
                        </div>
                        <div className={style.contentfont}>
                            Brain Binary Infotech is a leading iPhone application development company that uses the industry best coding methods and tools to identify and resolve bugs for the best user experience.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Swift app development
                        </div>
                        <div className={style.contentfont}>
                            Our iOS app developers focus on clean code and utilize Swift’s speed as well as the efficiency of Swift applications that provide the best user experience.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IsoServices