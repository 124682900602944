import React from 'react'
import style from './outsourcecompany.module.css'
import roundspring from '../../assets/roundspring.png'
import ContactUs from '../../components/contactus/ContactUs'
import outsoueceimage from '../../assets/outsorce-company/outsourceimage.png'
import outsourcingforbusiness1 from '../../assets/outsorce-company/outsourcingforbusiness1.png'
import outsourcingforbusiness2 from '../../assets/outsorce-company/outsourcingforbusiness2.png'
import outsourcingforbusiness3 from '../../assets/outsorce-company/outsourcingforbusiness3.png'
import GetFreeConsultancy from '../../components/getfreeconsultancy/GetFreeConsultancy'
import Experience from '../../components/expireance/Experience'
import OutsourcingServices from '../../components/outsourcingservices/OutsourcingServices'
import BenefitOutSourceServices from '../../components/numberbackground/benefitoutsourceservices/BenefitOutSourceServices'
import OutSourceService from '../../components/services/outsourceservice/OutSourceService'
import WordsFromClientele from '../../components/wordsfromClientele/WordsFromClientele'
import IndustryServe from '../../components/industryserve/IndustryServe'

const OutSourceCompany = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.development}>Outsource </span>
                            <span className={style.software}>Software </span>
                            <span className={style.company}>Development Company</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Brain Binary Infotech is a software outsourcing company that addresses the most complex business problems at the right moment to stay ahead of its competitors in the market. Get in touch with us to get software outsourcing services to improve business processes through seamless coding, end-to-end delivery, risk mitigation, and skilled resource availability.
                                </p>
                            </div>
                            <div>
                                <a href='#getfreeconsultancy' className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`}>Start Outsourcing Process</a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" className='h-100 w-100' />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-md-5 mt-3  text-end d-xl-inline d-flex justify-content-center position-relative">
                        <img src={outsoueceimage} className={style.outsourceimage} alt="Web Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowpadding}`}>
                    <div>
                        <h1 className={`${style.heading} ${style.headingborder}`}>Top-notch Software Development<br /> Outsourcing for your business</h1>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <div className='mt-4'>
                                <p className={style.content}>
                                    We have the ability to grow your business quickly by providing some of the best software outsourcing services. All these services are adapted to the specific requirements of your tasks so that software can be delivered within tight deadlines and budgets. At BrainBinary Infotech we have in-depth experience and knowledge of various industries and we help our clients to increase their productivity using high-quality software development tools and technologies.
                                </p>
                                <div>
                                    <button className={`btn mt-xl-5 mt-md-4 mt-3 ${style.getquotebtn}`}>Talk To Our Experts</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 ps-lg-5 mt-xl-0 mt-3 ">
                        <div className={style.box}>
                            <div className={style.boxcontent}>
                                <div className={style.round}><img src={outsourcingforbusiness1} alt="OutSourcing For Business" /></div>
                                <div>
                                    <div className={style.content}>Top Quality Talent</div>
                                    <div className={style.smallcontent}>You will have access to some of the best software developers that use powerful tech tools to work on a project with a tight deadline.</div>
                                </div>
                            </div>
                        </div>
                        <div className={style.box}>
                            <div className={style.boxcontent}>
                                <div className={style.round}><img src={outsourcingforbusiness2} alt="OutSourcing For Business" /></div>
                                <div>
                                    <div className={style.content}>Client First Approach</div>
                                    <div className={style.smallcontent}>Our developers follow a client-centric approach and always take care of the client's requirements. We provide real-time reports to the client regularly.</div>
                                </div>
                            </div>
                        </div>
                        <div className={style.box}>
                            <div className={style.boxcontent}>
                                <div className={style.round}><img src={outsourcingforbusiness3} alt="OutSourcing For Business" /></div>
                                <div>
                                    <div className={style.content}>Latest Dev Technology</div>
                                    <div className={style.smallcontent}>At Brain Binary Infotech, we use the latest dev technology to provide top-notch software outsourcing services for different types of businesses.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OutsourcingServices />
                <BenefitOutSourceServices />
                <OutSourceService />
                <Experience />
                <GetFreeConsultancy />
                <IndustryServe />
                <WordsFromClientele />
                <ContactUs />
            </div >
        </>
    )
}

export default OutSourceCompany