import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/fullstack/fullstack1.png'
import service2 from '../../../assets/fullstack/fullstack2.png'
import service3 from '../../../assets/fullstack/fullstack3.png'
import service4 from '../../../assets/fullstack/fullstack4.png'
import service5 from '../../../assets/fullstack/fullstack5.png'
import service6 from '../../../assets/fullstack/fullstack6.png'

const FullStackServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our Full Stack development Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Hire top-rated full stack developers from us that follow the best coding standards without compromising the design, development, and performance of websites and applications.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Front End Full Stack Development
                        </div>
                        <div className={style.contentfont}>
                            from Brain Binary Infotech that use front-end frameworks like HTML, CSS/SCSS, JavaScript, Angular, Vue.js, and Bootstrap to create engaging and visually appealing web and mobile applications. Get in touch with us to get the best user interfaces for all platforms and devices.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Back End Full Stack Development
                        </div>
                        <div className={style.contentfont}>
                            The full-stack developers at Brain Binary Infotech are trained in a wide range of back-end frameworks & languages, including Python, Laravel, Rails, Node.js, Golang, .NET, Java, & many others. Use our full-stack development services to get scalable, dependable, & feature-rich back-end solutions.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Full Stack Web Development
                        </div>
                        <div className={style.contentfont}>
                            Hire full-stack web developers from us to get the most dependable, enterprise-grade, and client-centric web app as per the requirements of your business. Our Full Stack software developers have the best qualifications for creating unique online solutions for startups & large-scale enterprises.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Full Stack Mobile Development
                        </div>
                        <div className={style.contentfont}>
                            Hire full-stack mobile app developers from us to create innovative mobile solutions that are safe and secure. We have full-stack mobile app developers that are highly skilled and experienced in different front-end and back-end technologies.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            MEAN Stack Developer
                        </div>
                        <div className={style.contentfont}>
                            At Brain Binary Infotech we offer different types of all-in-one MEAN stack development services that can fulfill your business requirements with advanced and user-friendly web applications according to the latest industry standards.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            LAMP Stack Developer
                        </div>
                        <div className={style.contentfont}>
                            We create scalable & reliable solutions utilizing Linux, Apache, MySQL, and PHP by combining some of the most well-known programming platforms & languages. To get different types of fully featured LAMP application development solutions hire LAMP stack developers from us.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullStackServices