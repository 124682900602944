import React from 'react'
import style from './industryserve.module.css'
import uiux1 from '../../assets/uiux/uiux1.png'
import uiux2 from '../../assets/uiux/uiux2.png'
import uiux3 from '../../assets/uiux/uiux3.png'
import uiux4 from '../../assets/uiux/uiux4.png'

const IndustryServe = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Industry We Serve
                </div>
            </div>
            <div className={`row ${style.industrydetail}`}>
                <div className={` col-sm-3 col-6 p-xl-4 p-md-3 p-2`}>
                    <div className={`${style.square}`}>
                        <img src={uiux1} className={style.industrydimage} alt="Food & Restaurants App" />
                        <div className={style.detailcontent}>Food & <br />Restaurants App</div>
                    </div>
                </div>
                <div className={` col-sm-3 col-6 p-xl-4 p-md-3 p-2`}>
                    <div className={`${style.square}`}>
                        <img src={uiux2} className={style.industrydimage} alt="Sports & Fantasy App" />
                        <div className={style.detailcontent}>Sports & Fantasy <br />App</div>
                    </div>
                </div>
                <div className={` col-sm-3 col-6 p-xl-4 p-md-3 p-2`}>
                    <div className={`${style.square}`}>
                        <img src={uiux3} className={style.industrydimage} alt="Education & Learning" />
                        <div className={style.detailcontent}>Education & <br />Learning</div>
                    </div>
                </div>
                <div className={` col-sm-3 col-6 p-xl-4 p-md-3 p-2`}>
                    <div className={`${style.square}`}>
                        <img src={uiux4} className={style.industrydimage} alt=" Video & Social Media App" />
                        <div className={style.detailcontent}>Video & Social<br /> Media App</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndustryServe