import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/reactjs/service1.png'
import service2 from '../../../assets/reactjs/service2.png'
import service3 from '../../../assets/reactjs/service3.png'
import service4 from '../../../assets/reactjs/service4.png'
import service5 from '../../../assets/reactjs/service5.png'
import service6 from '../../../assets/reactjs/service6.png'
import service7 from '../../../assets/reactjs/service7.png'
import service8 from '../../../assets/reactjs/service8.png'
import service9 from '../../../assets/reactjs/service9.png'

const ReactjsServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our ReactJS Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Hire React Js developers from us who have used their experience to provide different types of ReactJs development services to ensure that you can have highly scalable web and mobile applications.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Reactjs Product Development
                        </div>
                        <div className={style.contentfont}>
                            Our team of ReactJs experts builds dynamic and high-performing applications to satisfy your product requirements so that your business can stay ahead in this competitive world.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJs Front-End Development
                        </div>
                        <div className={style.contentfont}>
                            Hire experienced React.Js developers who specialize in front-end development and they create visually stunning and highly responsive user interfaces that provide exceptional user experiences.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJs Website Migration
                        </div>
                        <div className={style.contentfont}>
                            Our highly talented ReactJS programmers ensure a smooth migration process of your existing website from any platform to ReactJS while optimizing its performance and enhancing the user experience.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJs For Enterprise App Development
                        </div>
                        <div className={style.contentfont}>
                            Hire remote ReactJS programmers from us who build the best enterprise app applications that can streamline operations, enhance productivity, and offer amazing user experience.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJS Support & Maintenance
                        </div>
                        <div className={style.contentfont}>
                            Get world-class support and maintenance services from our developers who resolve every technical issue and offer updates to ensure that you can have a reliable ReactJs-based system.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJS UI/UX Development
                        </div>
                        <div className={style.contentfont}>
                            We have a team of experienced developers who follow the best design strategies to ensure that your web application provides a user-friendly experience for a better user retention rate.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJS Plugin Development
                        </div>
                        <div className={style.contentfont}>
                            Hire Industry’s best ReactJs developers who are skilled in creating customized components and plugins to ensure that they enhance the functionality and user experience of the ReactJs solution.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            ReactJS Integration
                        </div>
                        <div className={style.contentfont}>
                            Our experts provide amazing integration services while merging ReactJs with other systems, technologies, and various third-party APIs to ensure that your web application runs smoothly with all the necessary components.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            React Native App Development
                        </div>
                        <div className={style.contentfont}>
                            Hire proficient ReactJs developers from us who build high-performance mobile solutions that can run smoothly on both iOS and Android while ensuring that your app reaches maximum users.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReactjsServices