import React from 'react';
import style from './contactuspage.module.css';
import ContactUs from '../../components/contactus/ContactUs';

function ContactUsPage() {
    return (
        <div className={`${style.maindiv}`}>
            <ContactUs />
        </div>
    )
}

export default ContactUsPage;