import React from 'react'
import style from './flutter.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import flutterimage from '../../assets/flutter/flutterimage.png'
import FlutterServices from '../../components/services/flutter/FlutterServices'
import HireFlutter from '../../components/hiredevloperway/flutter/HireFlutter'
import FlutterWorkFlow from '../../components/workflow/flutter/FlutterWorkFlow'

const Flutter = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Hire </span>
                            <span className={style.development}>Flutter </span>
                            <span className={style.company}> Developers  </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Hire Flutter developers from us who build user-friendly, visually appealing, and feature-rich cross-platform applications. At Brain Binary Infotech, we have a team of dedicated Flutter app developers who utilize their expertise in frameworks like Mobx, Redux, Dart, and many more to create high-quality enterprise-level solutions that can run on different types of platforms like iOS, Android, Mac, and Windows.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Hire Dedicated Flutter Developer
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-xl-5 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={flutterimage} alt="" className={style.flutterimage} />
                        </div>
                        <div className="col-xl-7 col-12 p-lg-3 p-1 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                We have a team of top Flutter developers who follow the agile methodology and result-driven approach to build secure and robust applications with some of the most futuristic functionalities to grow your business. Brain Binary Infotech is a leading Flutter development company that is gaining popularity due to its efficiency in creating best-in-class cross-platform applications with a single codebase.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                Hire dedicated Flutter developers from us if you are trying to build dynamic and natively compiled applications that can run on Android as well as iOS according to your project requirements.
                            </div>
                        </div>
                    </div>
                </div>
                <FlutterServices />
                <FlutterWorkFlow/>
                <HireFlutter/>
                <ContactUs />
            </div >
        </>
    )
}

export default Flutter