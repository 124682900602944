import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoderPageRound() {
    return (
        <div className='position-fixed top-0 start-0 end-0 bg-white d-flex justify-content-center align-items-center'
            style={{ width: "100%", height: "100vh", zIndex: 9999 }}>
            <Spinner animation="border" role="status" style={{ color: "#0b618e" }} >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default LoderPageRound;