import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const HireAndroid = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Hiring Android App Developers in 3 easy steps
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    See these 3 steps which are given below so that you can get Android app development experts from us.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Discuss your project requirements and budget with us</h1>
                            <p className={style.boxcontent}>Our developers gather all the project requirements and expectations from your app to create the best roadmap to build dynamic android applications.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Appointment of the best Android App Developers</h1>
                            <p className={style.boxcontent}>During this phase, you can easily outsource Android app developers from us who only work on your app to provide cost-effective and feature-rich applications.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>On-Time Delivery of the Project</h1>
                            <p className={style.boxcontent}>We have a team of experts who always deliver the Android application before the deadline so that you can easily deploy it.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireAndroid