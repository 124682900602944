import React from 'react'
import style from './productengineearing.module.css'
import roundspring from '../../assets/roundspring.png'
import ContactUs from '../../components/contactus/ContactUs'
import productimage1 from '../../assets/product-engineering/productimage1.png'
import productimage2 from '../../assets/product-engineering/productimage2.png'
import GetFreeConsultancy from '../../components/getfreeconsultancy/GetFreeConsultancy'
import Experience from '../../components/expireance/Experience'
import WordsFromClientele from '../../components/wordsfromClientele/WordsFromClientele'
import ProductTechnology from '../../components/services/product-technology/ProductTechnology'
import ProductEngineearingServices from '../../components/hiredevloperway/productengineearing/ProductEngineearingServices'
import WhyBBProductEngi from '../../components/numberbackground/whybbproductengi/WhyBBProductEngi'
import IndustryServe from '../../components/industryserve/IndustryServe'

const ProductEngineearing = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12  d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Software</span>
                            <span className={style.development}>Product </span>
                            <span className={style.company}>Engineering</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Get in touch with our software product engineering company that designs, develops, and modernizes customized software to gain an advantage over your competitors. We provide leading  <span className={style.spancontent}> Software Product Engineering</span> services and configure better products for rapid growth for businesses.
                                </p>
                            </div>
                            <div>
                                <a href='#getfreeconsultancy' className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`}>Book a Free Consultation</a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" className='h-100 w-100'/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-md-5 mt-3 text-end position-relative">
                        <img src={productimage1} className={style.productimage} alt="Web Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowpadding}`}>
                    <div>
                        <h1 className={`${style.heading} ${style.headingborder}`}>Your One-Stop Accountable Solution To Get Impactful Software</h1>
                    </div>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>
                                    Brain Binary Infotech is a software product development company that creates the most useful software solutions for large enterprises and startups of various industries. We can assist you in resolving your business difficulties, increasing efficiency, and boosting business development by using our years of experience to create specialized digital solutions.
                                </p>
                                <p className={style.content}>
                                    We have a team of software product engineers that follows an agile methodology to create attractive and customized tech solutions
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-lg-5 ps-xl-3 text-end d-xl-inline d-flex justify-content-center  position-relative">
                        <img src={productimage2} className={style.productimage} alt="Web Devlopment" />
                    </div>
                </div>
                <ProductTechnology />
                <ProductEngineearingServices />
                <Experience />
                <GetFreeConsultancy />
                <WhyBBProductEngi />
                <IndustryServe />
                <WordsFromClientele />
                <ContactUs />
            </div >
        </>
    )
}

export default ProductEngineearing