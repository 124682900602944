import React from 'react'
import style from './appdevlopmentservices.module.css'
import roundimage from '../../assets/app-devlopment/serviceroundimage.png'
import service1 from '../../assets/app-devlopment/service1.png'
import service2 from '../../assets/app-devlopment/service2.png'
import service3 from '../../assets/app-devlopment/service3.png'
import service4 from '../../assets/app-devlopment/service4.png'

const AppDevlopmentServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div>
                    <div className={`heading headingborder`}>
                        Our Mobile App Development Service
                    </div>
                </div>
                <div>
                    <div className="row mt-5 d-flex justify-content-center">
                        <div className="col-sm-6 col-12 p-xl-4 p-md-3 p-sm-2 p-1">
                            <div className={style.servicebox}>
                                <img src={roundimage} className={style.roundimage} alt="Round" />
                                <div className='d-flex gap-3 align-items-center'>
                                    <img src={service1} className={style.serviceimage} alt="Android" />
                                    <h1 className={style.serviceheading}>Android</h1>
                                </div>
                                <p className={style.servicecontent}>Our full-stack Android developers create useful Android apps using the most up-to-date technologies and industry knowledge.our sketch service is the perfect solution for businesses and entrepreneurs looking to prototype their mobile app concepts with ease. </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 p-xl-4 p-md-3 p-sm-2 p-1">
                            <div className={style.servicebox}>
                                <img src={roundimage} className={style.roundimage} alt="Round" />
                                <div className='d-flex gap-3 align-items-center'>
                                    <img src={service2} className={style.serviceimage} alt="Android" />
                                    <h1 className={style.serviceheading}>ISO</h1>
                                </div>
                                <p className={style.servicecontent}>We can transform a simple app concept into profitable iPhone apps that benefit both your business and its users. We build iPad apps that bring value to your brand and your customers' lifestyles thanks to our extensive knowledge and skill in native iPad app development services. </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 p-xl-4 p-md-3 p-sm-2 p-1">
                            <div className={style.servicebox}>
                                <img src={roundimage} className={style.roundimage} alt="Round" />
                                <div className='d-flex gap-3 align-items-center'>
                                    <img src={service3} className={style.serviceimage} alt="Android" />
                                    <h1 className={style.serviceheading}>React Native</h1>
                                </div>
                                <p className={style.servicecontent}>Brain Binary  Infotech is a top mobile application development company in India. We are one of the leading React Native app developers who offer end-to-end mobile app solutions for our clients. Hire Dedicated React Native Developers your next Project. </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 p-xl-4 p-md-3 p-sm-2 p-1">
                            <div className={style.servicebox}>
                                <img src={roundimage} className={style.roundimage} alt="Round" />
                                <div className='d-flex gap-3 align-items-center'>
                                    <img src={service4} className={style.serviceimage} alt="Android" />
                                    <h1 className={style.serviceheading}>Cross-Platform</h1>
                                </div>
                                <p className={style.servicecontent}>Brain Binary Infotech is manufacture cross-platform Application solutions. Brain Binary Infotech is one of the best cross-platform app development companies in India/USA, to develop a mobile app for global Client at an affordable price.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppDevlopmentServices