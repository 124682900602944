import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const HireIso = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Hiring IOS Developers in 3 easy steps
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        It is easy to hire iOS developers from us and you can explore these 3 easy steps which are mentioned below.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Discuss your project requirements</h1>
                            <p className={style.boxcontent}>In this phase, our team understands your project’s objectives and designs the foundation for the development to ensure that the final iOS app perfectly matches your vision.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Appointment of the best iOS developers</h1>
                            <p className={style.boxcontent}>We have a large pool of developers from which you can hire remote iOS developers who can work according to your requirements so that you can have the best iOS app.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>On-Time Delivery of the Project</h1>
                            <p className={style.boxcontent}>Our programmers focus on punctuality, as they ensure that your iOS app is delivered on time so that it can be deployed according to the timeline.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireIso