import React from 'react'
import style from '../numberbackground.module.css'

const ItAppModernization = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Benefits of application modernization
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    At Brain Binary Infotech we provide the finest application modernization services to automate processes of different types of businesses. We provide some of the finest microservices so that clients can use our service of application management and enjoy some benefits.
                    </h1>
                </div>
                <div className='text-center'>
                    <button className={`btn ${style.submitbtn} mt-md-5 mt-3 px-4`} >
                        Talk To Our Experts
                    </button>
                </div>
                <div>
                    <div className="row mt-4">
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>01</h1>
                                    <p className={style.numbercontent}>Expertise</p>
                                </div>
                                <p className={style.boxcontent}>In-house IT consultants are chosen by some startups and mid-scale enterprises. However, you can get technical knowledge, an in-depth understanding of the latest solutions, and the tools and functionality your company requires by working with an IT consultant.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>02</h1>
                                    <p className={style.numbercontent}>Faster Project Completion</p>
                                </div>
                                <p className={style.boxcontent}>Get our IT consultancy services to complete your project quickly and with minimum time to achieve its full speed. Our experts work more efficiently and they use their experience, knowledge, and various tools to complete the project as soon as possible.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>03</h1>
                                    <p className={style.numbercontent}>Potential Cost Saving</p>
                                </div>
                                <p className={style.boxcontent}>Our team of IT consultants tries to use the latest technologies to complete the project as soon as possible so that it can be beneficial for the clients. We give a proper estimate to complete the project and try to complete it at the most affordable price.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>04</h1>
                                    <p className={style.numbercontent}>Reduce Security Risk</p>
                                </div>
                                <p className={style.boxcontent}>We provide IT consulting services that help to reduce the security threats in your application. Our team establishes certain protocols and processes to make sure that the application is secure from all types of threats.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>05</h1>
                                    <p className={style.numbercontent}>Increase Productivity</p>
                                </div>
                                <p className={style.boxcontent}>Hire in-house consultants to get more productivity on your project and many businesses prefer to get an in-house team to get the project done before the deadline. They work on your project as per the requirements and functionalities necessary for your business.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>06</h1>
                                    <p className={style.numbercontent}>Improved Processes</p>
                                </div>
                                <p className={style.boxcontent}>At Brain Binary Infotech our professionals have the technical expertise and they follow different types of processes to deliver the best application. They identify the problems and provide the best practices to improve the performance of the app.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>07</h1>
                                    <p className={style.numbercontent}>Meeting Compliances</p>
                                </div>
                                <p className={style.boxcontent}>During the improvement of the application, management will conduct a meeting to see which types of features are required in the upgraded app.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>08</h1>
                                    <p className={style.numbercontent}>Automation</p>
                                </div>
                                <p className={style.boxcontent}>Automation is the finest benefit of application management and modernization as it helps to increase development speed and reduce risks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ItAppModernization
