import React from 'react'
import webworkflow from '../../../assets/web-devlopment/webworkflow.png'
import style from '../webmobileworkflow.module.css'

const WebWorkFlow = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our Full Stack Development Workflow
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We use cutting-edge technologies, strong frameworks, and best industry practices to build and deliver your Full Stack application on schedule. Here is our Full Stack development workflow
                    </h1>
                </div>
                <div className={style.flowchart}>
                    <div className={style.box1}>
                        <div className={style.subheading}>Strategy & Formulation</div>
                    </div>
                    <div className={style.box2}>
                        <div className={style.subheading}>Designing</div>
                    </div>
                    <div className={style.box3}>
                        <div className={style.subheading}>Testing</div>
                    </div>
                    <div className={style.box4}>
                        <div className={style.subheading}>Idea</div>
                    </div>
                    <div className={style.box5}>
                        <div className={style.subheading}>Analysis &<br/> Planning</div>
                    </div>
                    <div className={style.box6}>
                        <div className={style.subheading}>Development</div>
                    </div>
                    <div className={style.box7}>
                        <div className={style.subheading}>Launch</div>
                    </div>
                    <div className='text-center'>
                        <img src={webworkflow} className={style.flowimage} alt="ReactJs Flow" />
                    </div>
                </div>
            </div>
        </>
    )   
}

export default WebWorkFlow
