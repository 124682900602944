import React, { useState } from 'react'
import style from './wendevlopment.module.css'
import roundspring from '../../assets/roundspring.png'
import webdevlopmentimage1 from '../../assets/web-devlopment/webdevlopment1.png'
import webdevlopmentimage2 from '../../assets/web-devlopment/webdevlopment2.png'
import webdevlopmentimage3 from '../../assets/web-devlopment/webdevlopment3.png'
import WevDevlopmentServices from '../../components/webdevlopmentservices/WevDevlopmentServices'
import InterviewForm from '../../components/interviewform/InterviewForm'
import WebWorkFlow from '../../components/webmobileworkflow/web/WebWorkFlow'
import ClientSlider from '../../components/clientslider/ClientSlider'
import ContactUs from '../../components/contactus/ContactUs'
import GetQuote from '../../components/getquote/GetQuote'

const WebDevlopment = () => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Best </span>
                            <span className={style.development}>Web Development </span>
                            <span className={style.company}>Company</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>We design and develop a website that is well-structured, clear, fully functional, and easy to navigate. We are a Website development company that provides services at affordable prices while following the industry’s best standards.
                                </p>
                            </div>
                            <div>
                                <button className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`} onClick={() => setShowModal(true)}>Request A Quote</button>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" className='h-100 w-100' />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-md-0 mt-3  text-end d-xl-inline d-flex justify-content-center">
                        <img src={webdevlopmentimage1} className={style.webimage} alt="Web Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowcolor}`}>
                    <div className="col-lg-7 col-12 pe-3 d-flex align-items-center">
                        <div className=''>
                            <div className={`${style.heading} ${style.headingborder}`}>
                                World-Class Web Development Services For Your Business
                            </div>
                            <p className={style.spancontent}>Web development is a dynamic field that encompasses the creation and maintenance of websites and web applications. It involves a combination of programming, design, and infrastructure management to deliver interactive and engaging online experiences. Various technologies play crucial roles in web development, each serving specific purposes and catering to different aspects of the development process.
                            </p>
                            <p className={style.spancontent}>Here's an introduction to some of the key technologies used in web development:
                            </p>
                            <p className={style.spancontent}>1. HTML &nbsp; 2. CSS &nbsp; 3. JavaScript &nbsp; 4. Frontend Frameworks/libraries &nbsp; 5. Backend Development &nbsp; 6. Databases &nbsp; 7. Web Servers &nbsp; 8. RESTful APIs &nbsp; 9. DevOps Tools
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 ps-lg-5 ps-3 text-end">
                        <img src={webdevlopmentimage2} className={style.mapimage} alt="Web Devlopment" />
                    </div>
                </div>
                <WevDevlopmentServices />
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Why choose Brain Binary Infotech for Web Development?
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-lg-5 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={webdevlopmentimage3} alt="Web Devlopment" className={style.choosewebimage} />
                        </div>
                        <div className="col-lg-7 col-12 mt-lg-0 mt-3 p-xl-5 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                Hire full stack software developers from us who create different types of mobile, web, or desktop applications from scratch. They utilize their knowledge of software development, databases, servers, MVC, APIs, and third-party plugins to create the most attractive and dynamic web solutions. Our programmers use tools and frameworks like NodeJs, Rapid.Js, MongoDB, AngularJS, React, jQuery, and many more to provide different types of full stack development solutions.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                Connect with our team of full stack developers that create user-engaging and appealing applications for your business to stay ahead of your competitors.
                            </div>
                        </div>
                    </div>
                </div>
                <InterviewForm />
                <WebWorkFlow />
                <div className='mb-lg-5 pb-lg-5 pb-md-4 mb-md-4 mb-3 pb-0'>
                    <ClientSlider />
                </div>
                <ContactUs />
                <GetQuote showModal={showModal} closeModal={() => setShowModal(false)} />
            </div >
        </>
    )
}

export default WebDevlopment