import React from 'react'
import style from '../numberbackground.module.css'

const AppModernization = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Benefits of application modernization
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        At Brain Binary Infotech we provide the finest application modernization services to automate processes of different types of businesses. We provide some of the finest microservices so that clients can use our service of application management and enjoy some benefits.
                    </h1>
                </div>
                <div className='text-center'>
                    <button className={`btn ${style.submitbtn} mt-xl-5 mt-3 px-4`} >
                        Talk To Our Experts
                    </button>
                </div>
                <div>
                    <div className="row mt-4">
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>01</h1>
                                    <p className={style.numbercontent}>Cost-Effective</p>
                                </div>
                                <p className={style.boxcontent}>Our professionals use different resources to ensure that the modernization procedure for the application can be done at an affordable price.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>02</h1>
                                    <p className={style.numbercontent}>Business Agility and Increased</p>
                                </div>
                                <p className={style.boxcontent}>One of the main benefits of app modernization and management is the flexibility to add new features and services to satisfy the requirements of your business.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>03</h1>
                                    <p className={style.numbercontent}>Staff Productivity</p>
                                </div>
                                <p className={style.boxcontent}>Due to the modernization of the application, the overall performance and productivity of the employees will be increased as they can use the app more efficiently.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>04</h1>
                                    <p className={style.numbercontent}>Enhanced Customer Experience</p>
                                </div>
                                <p className={style.boxcontent}>Once you have upgraded the application for your business, you can say that customer experience will be enhanced and feedback from the customers might be helpful.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>05</h1>
                                    <p className={style.numbercontent}>New Revenue Streams</p>
                                </div>
                                <p className={style.boxcontent}>Modern application of your business helps to reach future goals and try to create new revenue streams for the growth of your enterprise.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>06</h1>
                                    <p className={style.numbercontent}>Better Security</p>
                                </div>
                                <p className={style.boxcontent}>We increase the availability of applications on cloud platforms and ensure that it is technically flexible and provides better security.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>07</h1>
                                    <p className={style.numbercontent}>Meeting Compliances</p>
                                </div>
                                <p className={style.boxcontent}>During the improvement of the application, management will conduct a meeting to see which types of features are required in the upgraded app.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>08</h1>
                                    <p className={style.numbercontent}>Automation</p>
                                </div>
                                <p className={style.boxcontent}>Automation is the finest benefit of application management and modernization as it helps to increase development speed and reduce risks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AppModernization
