import React from 'react'
import style from './iso.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import androidimage from '../../assets/android/android1.png'
import IsoServices from '../../components/services/iso/IsoServices'
import IsoWorkFlow from '../../components/workflow/iso/IsoWorkFlow'
import HireIso from '../../components/hiredevloperway/iso/HireIso'

const Iso = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Hire </span>
                            <span className={style.development}>IOS</span>
                            <span className={style.company}> Developers  </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Hire iOS developers from us who stand out from others for their expertise in Swift and Objective-C to ensure the excellent performance and user experience of the iPhone applications. We have a team of the best iPhone developers who are dedicated to transforming your app ideas into reality and offer different types of iPhone app development services according to the unique requirements of our business.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Hire Dedicated IOS Developer
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-lg-5 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={androidimage} alt="" className={style.isoimage} />
                        </div>
                        <div className="col-lg-7 col-12 p-lg-3 mt-lg-0 mt-3 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                Hire a team of iOS programmers who use various tools and utilities like Swift, Swift UI, Xcode, VSCode, and many more to create feature-rich, modern, and flawless iOS applications according to the guidelines of the App Store so that your app can stand out from others. You should hire iPhone developers from Brain Binary Infotech who are committed to deliver exceptional iOS applications according to your custom requests.
                            </div>
                            <div className={`mt-md-4 mt-2 ${style.content}`}>
                                At Brain binary Infotech, we have a team of top-notch developers who follow a client-centric approach to understand your requirements and deliver the best android applications.
                            </div>
                        </div>
                    </div>
                </div>
                <IsoServices />
                <IsoWorkFlow />
                <HireIso />
                <ContactUs />
            </div >
        </>
    )
}

export default Iso