import React from 'react';
import style from './SatisfiedClientsCounter.module.css';
import { useEffect } from 'react';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import Marquee from "react-fast-marquee";

function SatisfiedClientsCounter() {

    
    const [SatisfiedClients, setSatisfiedClients] = React.useState([]);
    const [ClientImage, setClientImage] = React.useState([]);
    const [inView, setInView] = useState(false);


    const Get_SatisfiedClients = () => {
        axios.get("https://api.brainbinaryinfotech.com/satisfiedclientcount/")
            .then((res) => {
                setSatisfiedClients(res.data.CLIENTS);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const Get_ClientsImage = () => {
        axios.get("https://api.brainbinaryinfotech.com/satisfiedclientimages/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setClientImage(res.data.CLIENT_IMAGES);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        Get_SatisfiedClients();
        Get_ClientsImage();
    }, [])

    return (
        <div className='position-relative'>
            <div data-aos="zoom-in" data-aos-offset="100" className={`heading text-center fs-4 mt-4 p-4 w-100 d-flex flex-xl-row flex-lg-row flex-md-cloumn flex-column justify-content-center`}>
                Over &nbsp;
                <div className={style.SatisfiedClients}>
                    {
                        SatisfiedClients.map((el, index) => {
                            return (
                                <ScrollTrigger onEnter={() => setInView(true)} onExit={() => setInView(false)} key={index}>
                                    {inView && <CountUp start={0} end={el.clients} duration={1} />} + Satisfied Clients &nbsp;
                                </ScrollTrigger>
                            )
                        })
                    }
                </div>
                <span> Have Chosen Us as Their Trusted Partner </span>
            </div>
            <div className={`${style.SatisfiedClientsBgColor}`}>
                <Marquee direction="left" speed={100}>
                    {
                        ClientImage.concat(ClientImage).concat(ClientImage).map((el, index) => {
                            return (
                                <div key={index} className={style.ImgContainer}>
                                    <img data-aos="zoom-in" data-aos-offset="30" key={index} src={el.image.url} alt="ERROR 404" className={`${style.ImgSet} `} />
                                </div>
                            )
                        })
                    }
                </Marquee>
            </div>
        </div>
    )
}

export default SatisfiedClientsCounter;