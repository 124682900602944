import React from 'react'
import style from './uiux.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import uiuximage from '../../assets/uiux/uiuximage.png'
import UiUxWorkFlow from '../../components/workflow/uiux/UiUxWorkFlow'
import UiUxServices from '../../components/services/uiux/UiUxServices'
import UiUxIndustryServe from '../../components/industryserve/IndustryServe'

const UiUx = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Best </span>
                            <span className={style.development}>UI/UX Design </span>
                            <span className={style.company}> Analysis Services Company</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>We are the best UI/UX design company that understands the requirements of clients before designing UI. Our designers try to deliver the best design that is user-friendly so that the users can get a better experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Why Choose Brain Binary Infotech For UI/UX Design analysis services?
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-lg-5 col-12 p-xl-5 d-xl-inline d-flex justify-content-center"> 
                            <img src={uiuximage} alt="" className={style.uiuximage} />
                        </div>
                        <div className="col-lg-7 col-12 mt-lg-0 mt-3 p-xl-5 p-lg-3 p-1 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                In today's serious world, the user interface (UI) of a product, flexible program, or website is just as important as its functionality. Even if the features are fantastic, if it is difficult to use, it will be ineffective. Client satisfaction is built through UI and UX Design, just as it is through transformations, or more importantly, it ensures the success of the product application.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                We deliver the best UI and UX Design and Development Services utilizing all applicable standards and procedures, thanks to our team of UX experts and years of expertise. We provide UI/UX design services from start to end.
                            </div>
                        </div>
                    </div>
                </div>
                <UiUxServices />
                <UiUxWorkFlow />
                <UiUxIndustryServe />
                <ContactUs />
            </div >
        </>
    )
}

export default UiUx