import React from 'react'
import style from './careearinbb.module.css'
import careearimage from '../../assets/careears/careeariamge2.png'

const Careearinbb = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`${style.heading} ${style.headingborder}`} data-aos="fade-up">
                    How Are Careers @ Brain Binary Infotech?
                </div>
                <div className="row my-3 my-md-4 my-lg-5">
                    <div className="col-lg-7 col-12  py-xl-5 py-3 pe-xl-5 pe-sm-4" data-aos="fade-up">
                        <ul className={style.content}>
                            <li>Be part of a team of dedicated tech professionals who change and care for each other on a daily basis at every stage of your career</li>
                            <li>Join our friendly team and take the challenge. Let’s start businesses and help new entrepreneurs.</li>
                            <li>Be part of an experienced team of highly trained IT professional experts</li>
                            <li>As the development grows day by day, we are especially looking for new, small, innovative, and imaginative minds</li>
                            <li>Please contact us if you think you would like to work as a developer.</li>
                            <li>Be a part of our team in a diverse work atmosphere, focused on fostering business by delivering outstanding service</li>
                            <li>To broaden your learning, experience, and abilities, get an opportunity in our organization.</li>
                        </ul>
                    </div>
                    <div className="col-lg-5 col-12  text-end d-xl-inline d-flex justify-content-center" data-aos="fade-up">
                        <img src={careearimage} alt="" className={style.careearimage} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Careearinbb
