import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/flutter/flutterimage1.png'
import service2 from '../../../assets/flutter/flutterimage2.png'
import service3 from '../../../assets/flutter/flutterimage3.png'
import service4 from '../../../assets/flutter/flutterimage4.png'
import service5 from '../../../assets/flutter/flutterimage5.png'
import service6 from '../../../assets/flutter/flutterimage6.png'
import service7 from '../../../assets/flutter/flutterimage7.png'
import service8 from '../../../assets/flutter/flutterimage8.png'
import service9 from '../../../assets/flutter/flutterimage9.png'

const FlutterServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our Flutter Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We have a team of experienced Flutter developers who implement various real-time features to provide seamless performance for the different types of Flutter Development Services that you can get to have a feature-rich solution.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Cross-platform App Development
                        </div>
                        <div className={style.contentfont}>
                            Hire our Flutter experts who utilize the power of versatile and single-codebase applications for various platforms by delivering cost-effective solutions.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter for Web Development
                        </div>
                        <div className={style.contentfont}>
                            You should think about hiring Flutter developers from us who provide various Flutter web development services by creating responsive and feature-rich web applications.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter Widget Customization
                        </div>
                        <div className={style.contentfont}>
                            Our experienced app developers create customized Flutter widgets according to your unique requirements to provide a user-friendly app experience.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter Dart App Development
                        </div>
                        <div className={style.contentfont}>
                            Hire a Flutter developer from us who specializes in harnessing the power of Dart language to create high-performing cross-platform mobile applications that provide the best user experience.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter Native App Development
                        </div>
                        <div className={style.contentfont}>
                            Our Flutter developers leverage Flutter’s native capabilities to build high-quality, platform-specific mobile apps that provide top-notch performance according to industry standards.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            UI/UX Design Strategy Development
                        </div>
                        <div className={style.contentfont}>
                            Get in touch with our best Flutter developers who adapt the most attractive UI/UX design strategies to create visually appealing interfaces for the app to gain the attention of users.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter Support and Maintenance Services
                        </div>
                        <div className={style.contentfont}>
                            Hire Flutter app developers from us who provide robust support and maintenance services by ensuring that your Flutter app stays at performs well with regular updates.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter Consulting Services
                        </div>
                        <div className={style.contentfont}>
                            Get detailed Flutter consulting services from our team of highly skilled Flutter developers so that you can easily make the decisions for your Flutter project.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Flutter for Embedded Devices
                        </div>
                        <div className={style.contentfont}>
                            Our Flutter app developers utilize the versatility of Flutter to create applications for embedded systems that provide seamless user experiences for different types of devices.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FlutterServices