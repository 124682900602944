import React from 'react'
import style from './android.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import androidimage from '../../assets/android/android1.png'
import AndroidServices from '../../components/services/android/AndroidServices'
import HireAndroid from '../../components/hiredevloperway/android/HireAbdroid'
import AndroidWorkFlow from '../../components/workflow/android/AndroidWorkFlow'

const Android = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                <div className="col-lg-6 col-12 pe-3 position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Hire </span>
                            <span className={style.development}>Android App  </span>
                            <span className={style.company}> Developers  </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>At Brain Binary Infotech, we have a team of experienced Android app developers who turn your ideas into fully functional, user-friendly, and highly scalable android applications. Hire Android app developers from us who have a proven track record of using modern frameworks like Flutter, Ionic, jQuery, React, Android Studio, and many more to create top-notch applications for different types of businesses.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Hire Dedicated Android App Developer
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-xl-5 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={androidimage} alt="" className={style.androidimage} />
                        </div>
                        <div className="col-xl-7 col-12 mt-lg-0 mt-3 p-lg-3 p-1 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                Our top Android programmers use their deep understanding of this app development domain to create robust and expandable applications. Hire dedicated android developers who are highly skilled in the latest databases and languages like Oracle, Object Box, Java, Python, Kotlin, and many more to ensure that your application is better than others.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                At Brain binary Infotech, we have a team of top-notch developers who follow a client-centric approach to understand your requirements and deliver the best android applications.
                            </div>
                        </div>
                    </div>
                </div>
                <AndroidServices />
                <AndroidWorkFlow />
                <HireAndroid />
                <ContactUs />
            </div >
        </>
    )
}

export default Android