import React from 'react';
import OurService from '../../components/ourservices/OurService';
import style from './service.module.css';
import roundspring from '../../assets/roundspring.png'
// import ContactUs from '../../components/contactus/ContactUs';
import Haveanidea from '../../components/haveanidea/Haveanidea';


function Service() {
    return (
        <div className={`${style.maindiv}`}>
            <div className={style.springimage}>
                <img src={roundspring} alt="Rounded Spring" className='w-100 h-100' />
            </div>
            <OurService />
            {/* <ContactUs /> */}
            <Haveanidea />
        </div>
    )
}

export default Service;