import React from 'react'
import style from './nodejs.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import nodejsimage from '../../assets/nodejs/nodejsimage.png'
import NodejsServices from '../../components/services/nodejs/NodejsServices'
import NodeJsWorkFlow from '../../components/workflow/nodejs/NodeJsWorkFlow'
import HireNodeJs from '../../components/hiredevloperway/nodejs/HireNodeJs'

const NodeJs = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Hire </span>
                            <span className={style.development}>Node.js</span>
                            <span className={style.company}> Developers  </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Hire Node.Js developers from us to easily deploy MVPs and highly scalable futuristic products. Trying to get robust web and mobile applications? Our team of experienced programmers has got you covered as they use languages and platforms like Node.Js, JavaScript, TypeScript, ECMAScript, and many more to provide the best NodeJs development services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Hire Dedicated Node.js Developer
                    </div>
                    <div className="row mt-md-5 mt-4">
                        <div className="col-xl-5 col-md-6 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={nodejsimage} alt="" className={style.nodejsimage} />
                        </div>
                        <div className="col-xl-7 col-md-6 col-12 mt-lg-0 mt-3 p-lg-3 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                Brain Binaty Infotech offers you the best team of remote Node.Js developers who have profound knowledge in frameworks and libraries like Koa.Js, Socket.io, Babel, ExpressJS, and many more to ensure that you can get maximum ROI with innovative solutions. Our experienced Node.Js programmers are known for building world-class web solutions.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                Hire dedicated Node.Js developers from us who believe in providing the best solution to improve your product’s usability, satisfaction, and overall user experience
                            </div>
                        </div>
                    </div>
                </div>
                <NodejsServices />
                <NodeJsWorkFlow />
                <HireNodeJs />
                <ContactUs />
            </div >
        </>
    )
}

export default NodeJs