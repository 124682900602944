import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const HireFullStack = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    How to Hire Full Stack Developers in 3 easy steps
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Explore these simple steps that are mentioned below which you can follow to easily hire proficient Full Stack Developers from us.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Discuss your project requirements and budget with us</h1>
                            <p className={style.boxcontent}>Discuss with us all your project requirements and budget with us before you hire the developer. In this discussion, you can express your expectations from the project.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Appointment of the best Full Stack developers</h1>
                            <p className={style.boxcontent}>We will appoint you the dedicated developers that will deliver the project as per the client’s requirements. We will appoint developers whose skills are suitable for your expectations from the project.</p>
                        </div>
                    </div>
                     <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>On-Time Delivery of the Project</h1>
                            <p className={style.boxcontent}>Our developers will share the progress of your project regularly by sending reports of timelines, project progress, and budget. They will help to upload the application on PlayStore and App Store.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireFullStack