import React, { useEffect, useState } from 'react'
import style from './wordsfromclientele.module.css'
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import client1 from '../../assets/modernization-services/client1.png'

const WordsFromClientele = () => {
    const [slidesPerView, setSlidesPerView] = useState(window.innerWidth < 768 ? 1 : 2); 

    useEffect(() => {
        const handleResize = () => {
            setSlidesPerView(window.innerWidth < 768 ? 1 : 2); 
        };

        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Words From Our Clientele
                </div>
                <div className='position-relative'>
                    <Swiper
                        slidesPerView={slidesPerView}
                        modules={[Navigation, Pagination]}
                        className={`mySwiper ${style.sliderwidth}`}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        <SwiperSlide>
                            <div className={style.client}>
                                <h1 className={style.content}>Brain binary Infotech managed to finish the demo successfully and in a timely manner. The team was able to provide great and direct communication throughout the project. They were able to utilize new technologies that are available in the development industry.</h1>
                                <div className=''>
                                    <h1 className={style.clientname}>Esther Howard</h1>
                                    <p className={style.clientrole}>CEO, Promoprep</p>
                                    <img src={client1} className={style.clientimage} alt="Client" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={style.client}>
                                <h1 className={style.content}>Brain binary Infotech managed to finish the demo successfully and in a timely manner. The team was able to provide great and direct communication throughout the project. They were able to utilize new technologies that are available in the development industry.</h1>
                                <div className=''>
                                    <h1 className={style.clientname}>Esther Howard</h1>
                                    <p className={style.clientrole}>CEO, Promoprep</p>
                                    <img src={client1} className={style.clientimage} alt="Client" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={style.client}>
                                <h1 className={style.content}>Brain binary Infotech managed to finish the demo successfully and in a timely manner. The team was able to provide great and direct communication throughout the project. They were able to utilize new technologies that are available in the development industry.</h1>
                                <div className=''>
                                    <h1 className={style.clientname}>Esther Howard</h1>
                                    <p className={style.clientrole}>CEO, Promoprep</p>
                                    <img src={client1} className={style.clientimage} alt="Client" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={style.client}>
                                <h1 className={style.content}>Brain binary Infotech managed to finish the demo successfully and in a timely manner. The team was able to provide great and direct communication throughout the project. They were able to utilize new technologies that are available in the development industry.</h1>
                                <div className=''>
                                    <h1 className={style.clientname}>Esther Howard</h1>
                                    <p className={style.clientrole}>CEO, Promoprep</p>
                                    <img src={client1} className={style.clientimage} alt="Client" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className={`swiper-button-prev ${style.navigationButton}`}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0625 6.56245H2.94375L6.34688 2.47495C6.50601 2.2835 6.58256 2.03667 6.55971 1.78877C6.53685 1.54088 6.41645 1.31221 6.225 1.15308C6.03355 0.993948 5.78672 0.91739 5.53882 0.940246C5.29092 0.963102 5.06225 1.0835 4.90312 1.27495L0.215625 6.89995C0.184088 6.94469 0.155887 6.9917 0.13125 7.04058C0.13125 7.08745 0.13125 7.11558 0.0656252 7.16245C0.0231316 7.26995 0.000882301 7.38437 0 7.49995C0.000882301 7.61554 0.0231316 7.72996 0.0656252 7.83745C0.0656252 7.88433 0.0656249 7.91245 0.13125 7.95933C0.155887 8.00821 0.184088 8.05521 0.215625 8.09995L4.90312 13.725C4.99127 13.8308 5.10165 13.9159 5.22642 13.9742C5.35118 14.0326 5.48727 14.0627 5.625 14.0625C5.84405 14.0629 6.05633 13.9866 6.225 13.8468C6.31993 13.7681 6.3984 13.6715 6.45592 13.5624C6.51344 13.4533 6.54887 13.334 6.56019 13.2112C6.57151 13.0884 6.5585 12.9646 6.52189 12.8468C6.48529 12.7291 6.42582 12.6197 6.34688 12.525L2.94375 8.43745H14.0625C14.3111 8.43745 14.5496 8.33868 14.7254 8.16287C14.9012 7.98705 15 7.74859 15 7.49995C15 7.25131 14.9012 7.01286 14.7254 6.83704C14.5496 6.66123 14.3111 6.56245 14.0625 6.56245Z" fill="url(#paint0_linear_243_762)" />
                            <defs>
                                <linearGradient id="paint0_linear_243_762" x1="7.5" y1="0.936279" x2="7.5" y2="14.0625" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#68B1D2" />
                                    <stop offset="1" stopColor="#0B618E" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={`swiper-button-next ${style.navigationButton}`}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.937499 6.56245H12.0562L8.65312 2.47495C8.49399 2.2835 8.41744 2.03667 8.44029 1.78877C8.46315 1.54088 8.58355 1.31221 8.775 1.15308C8.96645 0.993948 9.21328 0.91739 9.46118 0.940246C9.70908 0.963102 9.93775 1.0835 10.0969 1.27495L14.7844 6.89995C14.8159 6.94469 14.8441 6.9917 14.8688 7.04058C14.8688 7.08745 14.8687 7.11558 14.9344 7.16245C14.9769 7.26995 14.9991 7.38437 15 7.49995C14.9991 7.61554 14.9769 7.72996 14.9344 7.83745C14.9344 7.88433 14.9344 7.91245 14.8688 7.95933C14.8441 8.00821 14.8159 8.05521 14.7844 8.09995L10.0969 13.725C10.0087 13.8308 9.89835 13.9159 9.77358 13.9742C9.64882 14.0326 9.51273 14.0627 9.375 14.0625C9.15595 14.0629 8.94367 13.9866 8.775 13.8468C8.68007 13.7681 8.6016 13.6715 8.54408 13.5624C8.48656 13.4533 8.45113 13.334 8.43981 13.2112C8.42849 13.0884 8.4415 12.9646 8.47811 12.8468C8.51471 12.7291 8.57418 12.6197 8.65312 12.525L12.0562 8.43745H0.937499C0.688859 8.43745 0.450401 8.33868 0.274586 8.16287C0.0987701 7.98705 -9.53674e-07 7.74859 -9.53674e-07 7.49995C-9.53674e-07 7.25131 0.0987701 7.01286 0.274586 6.83704C0.450401 6.66123 0.688859 6.56245 0.937499 6.56245Z" fill="url(#paint0_linear_243_317)" />
                            <defs>
                                <linearGradient id="paint0_linear_243_317" x1="7.5" y1="0.936279" x2="7.5" y2="14.0625" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#68B1D2" />
                                    <stop offset="1" stopColor="#0B618E" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WordsFromClientele