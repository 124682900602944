import React from 'react'
import style from '../numberbackground.module.css'

const BenefitOutSourceServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Benefits you get with Software Outsource Services
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        There are many benefits you get with software outsource services and here you will find some of them. Business owners prefer outsourcing software development because it provides some of the most useful advantages like improved efficiency, less risk, better security, and cost reduction.
                    </h1>
                </div>
                <div>
                    <div className="row mt-4">
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>01</h1>
                                    <p className={style.numbercontent}>Improve your projects performance</p>
                                </div>
                                <p className={style.boxcontent}>Leverage the expertise of our programmers who use backend languages like Java, Node, PHP and frontend languages like Angular, React to make sure that the project is more productive and performing well.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>02</h1>
                                    <p className={style.numbercontent}>Enjoy working with a flexible business partner</p>
                                </div>
                                <p className={style.boxcontent}>Get in touch with us to enjoy working with a flexible business partner because our dedicated software development team will work with your team. Plus, we also offer flexible business models and you can choose as per the requirements of your project.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>03</h1>
                                    <p className={style.numbercontent}>Reduce inherent project tasks</p>
                                </div>
                                <p className={style.boxcontent}>At Brain Binary Infotech we eliminate the extra code from the project which isn’t necessary to maintain the high quality and best performance of the software.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>04</h1>
                                    <p className={style.numbercontent}>Boost your products security</p>
                                </div>
                                <p className={style.boxcontent}>The security of your product is one of the main benefits of our software outsource services. We follow the best coding standards and regularly maintain the product to make sure that your product is secure from all types of threats.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BenefitOutSourceServices