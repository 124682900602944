import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FaRegPlusSquare } from "react-icons/fa";

function CurrentOpenings() {

    const [show, setShow] = useState(false);
    const [CurrentJobOpenings, setCurrentJobOpenings] = React.useState([]);
    const [intialvalues, setintialvalues] = React.useState({ JobTitle: "", Location: "", Experience: "", Position: "", CurrentOpeningsDescription: "", SkillsAbilitiesRequired: "" });
    const [Eid, setEid] = React.useState(null);
    const [loader, Setloader] = useState(false);


    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false)
        setintialvalues({ JobTitle: "", Location: "", Experience: "", Position: "", CurrentOpeningsDescription: "", SkillsAbilitiesRequired: "" })
        setEid(null)
    }


    const Get_CurrentJobOpenings = () => {
        axios.get("https://api.brainbinaryinfotech.com/careercurrentopenings/")
            .then((res) => {
                setCurrentJobOpenings(res.data.POSITIONS);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }
    

    const Delete_CurrentOpenings = (_id) => {

        axios.delete("https://api.brainbinaryinfotech.com/careercurrentopenings/" + _id)
            .then((res) => {
                // toast.success(res.data.message.toLowerCase())
                Get_CurrentJobOpenings()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Delete_Confirmation = (_id) => {
        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Job Position !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_CurrentOpenings(_id)
                Swal.fire({
                    title: "Deleted!",
                    text: "Job Position has been deleted Sucessfully.",
                    icon: "success",
                    confirmButtonColor: "green",
                });
            }
        });

    }


    const Edit_CurrentJobOpenings = (value, _id) => {

        setintialvalues({
            JobTitle: value.JobTitle,
            Location: value.Location,
            Experience: value.Experience,
            Position: value.Position,
            CurrentOpeningsDescription: value.CurrentOpeningsDescription,
            SkillsAbilitiesRequired: value.SkillsAbilitiesRequired ? value.SkillsAbilitiesRequired.join(', ') : ''
        })
        setEid(_id)
        handleShow()
    }


    useEffect(() => {
        Get_CurrentJobOpenings()
        AOS.init();
    }, [])

    if (!loader) {
        return <LoderPageRound />
    }

    const Openings_Job_Schema = Yup.object().shape({
        JobTitle: Yup.string()
            .required('Job Title is required !'),
        Location: Yup.string()
            .required('Location is required !'),
        Experience: Yup.string()
            .required('Experience is required !'),
        Position: Yup.number()
            .required('Position is required !'),
        CurrentOpeningsDescription: Yup.string()
            .required('Current Openings Description  is required !'),
        SkillsAbilitiesRequired: Yup.string()
            .required('Skills & Abilities is required !')
    });



    return (
        <div data-aos="fade-up">
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="d-flex align-items-center ps-3">
                            <FaRegPlusSquare className="fs-5 me-2" />
                            <h6 className="mb-0"> {Eid ? 'Update' : 'Add'} Position </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={intialvalues}
                    enableReinitialize
                    validationSchema={Openings_Job_Schema}
                    onSubmit={async (values, action) => {

                        if (Eid) {
                            axios.put("https://api.brainbinaryinfotech.com/careercurrentopenings/" + Eid, values)
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_CurrentJobOpenings()
                                    handleClose()
                                    setEid(null)
                                    action.resetForm()
                                })
                                .catch((err) => {
                                    console.log(err);
                                })
                        }
                        else {
                            axios.post("https://api.brainbinaryinfotech.com/careercurrentopenings/add", values)
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_CurrentJobOpenings()
                                    handleClose()
                                    action.resetForm()
                                })
                                .catch((err) => {
                                    toast.error(err.response.data.message)
                                })
                        }

                    }}
                >
                    <Form>
                        <Modal.Body>

                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="JobTitle" className='mb-2'> Position Title </label>
                                    <div className="input-group mb-2">
                                        <Field name='JobTitle' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='JobTitle' component={'p'} className='text-danger' /> </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="Location" className='mb-2'> Location </label>
                                    <div className="input-group mb-2">
                                        <Field name='Location' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='Location' component={'p'} className='text-danger' /> </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="Experience" className='mb-2'> Experience </label>
                                    <div className="input-group mb-2">
                                        <Field name='Experience' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='Experience' component={'p'} className='text-danger' /> </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="Position" className='mb-2'> Position </label>
                                    <div className="input-group mb-2">
                                        <Field name='Position' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='Position' component={'p'} className='text-danger' /> </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="CurrentOpeningsDescription" className='mb-2'> Description </label>
                                    <div className="input-group mb-2">
                                        <Field name='CurrentOpeningsDescription' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='CurrentOpeningsDescription' component={'p'} className='text-danger' /> </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 px-3">
                                    <label htmlFor="SkillsAbilitiesRequired" className='mb-2'> Add Skills & Abilities For Job Position </label>
                                    <div className="input-group mb-2">
                                        <Field name='SkillsAbilitiesRequired' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <span> <ErrorMessage name='SkillsAbilitiesRequired' component={'p'} className='text-danger' /> </span>
                                </div>
                            </div>



                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ backgroundColor: "#0B618E" }} className="border-0" type='submit'>
                                {
                                    Eid ? <>  <FaRegSave className="fs-5 me-1" /> Save </> : <> <IoCheckmarkDoneSharp className="fs-5 me-1" /> Submit </>
                                }
                            </Button>
                            <Button style={{ backgroundColor: "#0B618E" }} className="border-0" onClick={() => handleClose()}>  <MdOutlineCancel className="fs-5" /> Cancel</Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>

            <div className='d-flex justify-content-between align-items-center px-2'>
                <div className="d-flex">
                    <h4 style={{ color: "#0B618E" }}> Current Openings ( Job Position ) </h4>
                </div>
                <Button variant="primary" className='border-0 d-flex align-items-center' style={{ backgroundColor: "#0B618E" }} onClick={handleShow}>
                    Add Position <FaRegPlusSquare className="fs-5 ms-1" />
                </Button>
            </div>
            <div className='overflow-x-auto px-2' id='ScrollBarSetting'>
                <table className="table table-striped my-5" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="align-middle text-center" style={{ color: "#0B618E", fontWeight: "normal" }}> ID </th>
                            <th scope="col" className="align-middle text-left" style={{ color: "#0B618E", fontWeight: "normal" }}> Job Title </th>
                            <th scope="col" className="align-middle text-left" style={{ color: "#0B618E", fontWeight: "normal" }}> Location </th>
                            <th scope="col" className="align-middle text-center" style={{ color: "#0B618E", fontWeight: "normal" }}> Experience </th>
                            <th scope="col" className="align-middle text-center" style={{ color: "#0B618E", fontWeight: "normal" }}> Position </th>
                            <th scope="col" className="align-middle text-center" style={{ color: "#0B618E", fontWeight: "normal" }}> Openings Description </th>
                            <th scope="col" className="align-middle text-center" style={{ color: "#0B618E", fontWeight: "normal" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CurrentJobOpenings.map((el, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr style={{ borderBottom: "1px solid #ddd" }}>
                                        <td className="text-center align-middle" style={{ padding: "10px" }}>{index + 1}</td>
                                        <td className="text-left align-middle" style={{ padding: "10px" }}>{el.JobTitle}</td>
                                        <td className="text-left align-middle" style={{ padding: "10px" }}>{el.Location}</td>
                                        <td className="text-center align-middle" style={{ padding: "10px" }}>{el.Experience}</td>
                                        <td className="text-center align-middle" style={{ padding: "10px" }}>{el.Position}</td>
                                        <td className="text-left align-middle" style={{ padding: "10px" }}>{el.CurrentOpeningsDescription}</td>
                                        <td className="text-center align-middle" style={{ padding: "10px", textWrap: "nowrap" }}>
                                            <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className="pointer fs-5 me-3" style={{ color: "red" }} />
                                            <FaRegEdit onClick={() => Edit_CurrentJobOpenings(el, el._id)} className="pointer fs-5" style={{ color: "green" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className="text-left align-middle" style={{ padding: "10px" }}>
                                            <ul className='P-0'>
                                                <li className='list-unstyled'>
                                                    <h6 style={{ color: "#0B618E" }}> Skills & Abilities Required For Job Position  </h6>
                                                </li>
                                                {el.SkillsAbilitiesRequired.map((el, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {el}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default CurrentOpenings;