import React from 'react';
import style from './blogspage.module.css';
import CompanyExperience from '../../components/companyexperience/CompanyExperience';
// import ContactUs from '../../components/contactus/ContactUs';
import Blogs from '../../components/blogs/Blogs';
import Haveanidea from '../../components/haveanidea/Haveanidea';


const BlogsPage = () => {
    return (
        <div className={`${style.maindiv}`}>
            <Blogs />
            <CompanyExperience />
            <Haveanidea />
            {/* <ContactUs /> */}
        </div>
    )
}

export default BlogsPage;
