import React from 'react'
import style from './webdelopmentservices.module.css'
import service1 from '../../assets/web-devlopment/service1.png'
import service2 from '../../assets/web-devlopment/service2.png'
import service3 from '../../assets/web-devlopment/service3.png'
import service4 from '../../assets/web-devlopment/service4.png'
import service5 from '../../assets/web-devlopment/service5.png'
import service6 from '../../assets/web-devlopment/sevice6.png'

const WevDevlopmentServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our Web Development Service
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We specialize in delivering high-quality web development solutions tailored to meet the unique needs and goals of our clients. With our team of experienced developers and designers, we offer a comprehensive range of web development services to help businesses establish a strong online presence and achieve their objectives.
                    </h1>
                </div>
            </div>
            <div className={style.technologydiv}>
                <div className={`d-flex justify-content-between ${style.langmenu}`}>
                    <div className={` ${style.langdiv}`}>
                        <div>
                            <img src={service1} className={style.serviceimage} alt="Angular JS" />
                        </div>
                        <div className={style.servicecontent}>Angular JS</div>
                    </div>
                    <div className={`${style.langdiv}`}>
                        <div>
                            <img src={service2} className={style.serviceimage} alt="Python" />
                        </div>
                        <div className={style.servicecontent}>Python</div>
                    </div>
                    <div className={`${style.langdiv}`}>
                        <div>
                            <img src={service3} className={style.serviceimage} alt="Ruby On Rails" />
                        </div>
                        <div className={style.servicecontent}>Ruby On Rails </div>
                    </div>
                    <div className={`${style.langdiv}`}>
                        <div>
                            <img src={service4} className={style.serviceimage} alt="ReactJS" />
                        </div>
                        <div>ReactJS</div>
                    </div>
                    <div className={`${style.langdiv}`}>
                        <div>
                            <img src={service5} className={style.serviceimage} alt="Node.js" />
                        </div>
                        <div className={style.servicecontent}>Node.js</div>
                    </div>
                    <div className={`${style.langdiv}`}>
                        <div>
                            <img src={service6} className={style.serviceimage} alt="jQuery" />
                        </div>
                        <div className={style.servicecontent}>jQuery</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WevDevlopmentServices
