import React from 'react'
import style from '../service.module.css'
import itscaleupteam1 from '../../../assets/itstaff/itscaleupteam1.png'
import itscaleupteam2 from '../../../assets/itstaff/itscaleupteam2.png'
import itscaleupteam3 from '../../../assets/itstaff/itscaleupteam3.png'

const ItScaleUpTeam = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Scale-up Your Team the Way You Need
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={itscaleupteam1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Build a Successful Team from Scratch
                        </div>
                        <div className={style.contentfont}>
                            Create a team of specialists with a range of skills to turn your business idea into a fully functional product from zero.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={itscaleupteam2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Add Resources in Your Existing Project
                        </div>
                        <div className={style.contentfont}>
                            If you want to scale and grow more quickly, bring in more talent to your current workforce. Hire dedicated software developers who increase the development efficiency of your project.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={itscaleupteam3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Vendor Transition
                        </div>
                        <div className={style.contentfont}>
                            We provide IT operation consultation service that helps to increase efficiency, and speed, and reduce the cost of IT operations by automating them.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItScaleUpTeam