import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/nodejs/nodejsservices1.png'
import service2 from '../../../assets/nodejs/nodejsservices2.png'
import service3 from '../../../assets/nodejs/nodejsservices3.png'
import service4 from '../../../assets/nodejs/nodejsservices4.png'
import service5 from '../../../assets/nodejs/nodejsservices5.png'
import service6 from '../../../assets/nodejs/nodejsservices6.png'
import service7 from '../../../assets/nodejs/nodejsservices7.png'
import service8 from '../../../assets/nodejs/nodejsservices8.png'
import service9 from '../../../assets/nodejs/nodejsservices9.png'

const NodejsServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our Node.js Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Hire top Node.Js developers from us who provide some of the most amazing Node.Js development services according to the requirements of your business.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            System Architecture Design
                        </div>
                        <div className={style.contentfont}>
                            Our NodeJs experts create the best system architecture designs by following the industry’s best coding standards to build strong backbones of reliable and scalable applications.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            API Integration and Development
                        </div>
                        <div className={style.contentfont}>
                            We have a team of Node.Js coders who specialize in creating robust APIs and ensuring seamless integration with different types of third-party services and applications.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Node.js Web Apps
                        </div>
                        <div className={style.contentfont}>
                            Get in touch with our programmers who build best-in-class web applications that are highly scalable and efficient. These apps provide incomparable performance and responsiveness.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Node.JS Plugin Development
                        </div>
                        <div className={style.contentfont}>
                            Hire best Node.Js developers from us who create customized NodeJs plugins that can be integrated easily and help to improve the application’s functionality and overall user experience.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Real-Time Chat Apps
                        </div>
                        <div className={style.contentfont}>
                            Our skilled developers leverage the power of NodeJs to build feature-rich real-time chat applications that ensure instant messaging, collaboration, and better connectivity.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Nodejs Application Migration
                        </div>
                        <div className={style.contentfont}>
                            We have a team of NodeJs developers who provide flawless migration processes while ensuring that your application is up-to-date according to the latest version of NodeJs and works efficiently.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Portal Development
                        </div>
                        <div className={style.contentfont}>
                            Consult with our developers if you are trying to get dynamic, interactive, and feature-rich portals that provide the best user experience to enhance the overall functionality of your business.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Serverless Node.js Microservice
                        </div>
                        <div className={style.contentfont}>
                            Our developers utilize the full potential of serverless architecture to build lightweight, efficient, and quickly scalable microservices at cost-effective rates.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            IoT Solutions with Node-Red
                        </div>
                        <div className={style.contentfont}>
                            We provide different types of IoT solutions with Node-Red which can easily connect, manage, and analyze data from different types of devices to grow your business.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NodejsServices