import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineChartBarSquare } from "react-icons/hi2";
import { FaRegSave } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IoCheckmarkDoneSharp } from "react-icons/io5";


const Category_Schema = Yup.object().shape({
    technology_category_name: Yup.string()
        .required('Category name is required !'),
});


function CategoriesTech() {
    const [show, setShow] = useState(false);
    const [Categories, setCategories] = React.useState([]);
    const [intialvalues, setintialvalues] = React.useState({ technology_category_name: '', });
    const [Eid, setEid] = React.useState(null);
    const [loader, Setloader] = useState(false);


    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false)
        setintialvalues({ technology_category_name: '' })
        setEid(null)
    }


    const Get_Categories = () => {
        axios.get("https://api.brainbinaryinfotech.com/ourTechnologycategories/")
            .then((res) => {
                setCategories(res.data.TECH_CATEGORIES);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }

    const Delete_Categories = (_id) => {

        axios.delete("https://api.brainbinaryinfotech.com/ourTechnologycategories/" + _id)
            .then((res) => {
                // toast.success(res.data.message.toLowerCase())
                Get_Categories()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Delete_Confirmation = (_id) => {
        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Category !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_Categories(_id)
                Swal.fire({
                    title: "Deleted!",
                    text: "Category has been deleted Sucessfully.",
                    icon: "success",
                    confirmButtonColor: "green",
                });
            }
        });

    }


    const Edit_Categories = (value, _id) => {

        setintialvalues(value)
        setEid(_id)
        handleShow()
    }


    useEffect(() => {
        Get_Categories()
        AOS.init();
    }, [])

    if (!loader) {
        return <LoderPageRound />
    }


    return (
        <div data-aos="fade-up">
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="d-flex align-items-center">
                            <HiOutlineChartBarSquare className="fs-4 me-1" />
                            <h6 className="mb-0"> {Eid ? 'Update' : 'Add'} Category </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={intialvalues}
                    enableReinitialize
                    validationSchema={Category_Schema}
                    onSubmit={async (values, action) => {

                        if (Eid) {
                            axios.put("https://api.brainbinaryinfotech.com/ourTechnologycategories/" + Eid, values)
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_Categories()
                                    handleClose()
                                    setEid(null)
                                    action.resetForm()
                                })
                                .catch((err) => {
                                    console.log(err);
                                })
                        }
                        else {
                            axios.post("https://api.brainbinaryinfotech.com/ourTechnologycategories/add", values)
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_Categories()
                                    handleClose()
                                    action.resetForm()
                                })
                                .catch((err) => {
                                    toast.error(err.response.data.message.toLowerCase())
                                })
                        }

                        setintialvalues({ technology_category_name: '' })
                        action.resetForm()

                    }}
                >
                    <Form>
                        <Modal.Body>
                            <label htmlFor="Name" className='mb-2'> Name </label>
                            <div className="input-group mb-2">
                                <Field name='technology_category_name' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                            </div>
                            <span> <ErrorMessage name='technology_category_name' component={'p'} className='text-danger' /> </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ backgroundColor: "#0B618E" }} className="border-0" type='submit'>
                                {
                                    Eid ? <>  <FaRegSave className="fs-5 me-1" /> Save </> : <> <IoCheckmarkDoneSharp className="fs-5 me-1" /> Submit </>
                                }
                            </Button>
                            <Button style={{ backgroundColor: "#0B618E" }} className="border-0" onClick={() => handleClose()}>  <MdOutlineCancel className="fs-5" /> Cancel</Button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>

            <div className='d-flex justify-content-between align-items-center px-2'>
                <div className="d-flex">
                    <h4 style={{ color: "#0B618E" }}>Our Technologies ( Categories ) </h4>
                </div>
                <Button variant="primary" className='border-0 d-flex align-items-center' style={{ backgroundColor: "#0B618E" }} onClick={handleShow}>
                    Add Category <HiOutlineChartBarSquare className="fs-3 ms-1" />
                </Button>
            </div>
            <div className='overflow-x-auto px-2' id='ScrollBarSetting'>
                <table className="table table-striped my-5" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="text-center" style={{ color: "#0B618E", fontWeight: "normal" }}>ID</th>
                            <th scope="col" className="text-left" style={{ color: "#0B618E", fontWeight: "normal" }}>Categories Name</th>
                            <th scope="col" className="text-center" style={{ color: "#0B618E", fontWeight: "normal" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Categories.map((el, index) => {
                            return (
                                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td className="text-center align-middle" style={{ padding: "10px" }}>{index + 1}</td>
                                    <td className="text-left align-middle" style={{ padding: "10px" }}>{el.technology_category_name}</td>
                                    <td className="text-center align-middle" style={{ padding: "10px" }}>
                                        <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className="pointer fs-5 me-3" style={{ color: "red" }} />
                                        <FaRegEdit onClick={() => Edit_Categories(el, el._id)} className="pointer fs-5" style={{ color: "green" }} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CategoriesTech;