import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/outsorce-company/outsourceservice1.png'
import service2 from '../../../assets/outsorce-company/outsourceservice2.png'
import service3 from '../../../assets/outsorce-company/outsourceservice3.png'
import service4 from '../../../assets/outsorce-company/outsourceservice4.png'
import service5 from '../../../assets/outsorce-company/outsourceservice5.png'
import service6 from '../../../assets/outsorce-company/outsourceservice6.png'

const OutSourceService = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Why Choose BrainBinary Infotech for Software Outsource service ?
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    We are the best software outsourcing agency that ensures the finest product quality solutions. Choose Brain Binary Infotech for a software development outsourcing company as we follow premier methodologies to develop feature-rich software.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Quality IT Specialists
                        </div>
                        <div className={style.contentfont}>
                        We have a team of the highest quality IT specialists that are dedicated to your project. Our experienced programmers...
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Cost Saving
                        </div>
                        <div className={style.contentfont}>
                        There are many benefits of outsourcing software development and the main benefit is cost saving. Clients don’t have t...
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Simplified Communication
                        </div>
                        <div className={style.contentfont}>
                        Our clear and open procedure enables active communication between the customer and the outsourcing team for a...
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Effective project Management
                        </div>
                        <div className={style.contentfont}>
                        We make an effort to reduce project risk and maintain all responsibility for the project's purpose, deadline, and budget.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Scalable Teams
                        </div>
                        <div className={style.contentfont}>
                        We provide a team of experts to match your unique business requirements and provide the most valuable results project...
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                        Advantageous Experience
                        </div>
                        <div className={style.contentfont}>
                        Our team of experts has proven their expertise by delivering the best solution for various businesses and we have experience in handling different 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OutSourceService