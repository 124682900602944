import React from 'react'
import style from './nativeappdevlopment.module.css'
import modernization1 from '../../assets/modernization-services/modernization1.png'
import modernization2 from '../../assets/modernization-services/modernization2.png'
import modernization3 from '../../assets/modernization-services/modernization3.png'
import modernization4 from '../../assets/modernization-services/modernization4.png'
import modernization5 from '../../assets/modernization-services/modernization5.png'
import modernization6 from '../../assets/modernization-services/modernization6.png'

const NativeAppDevlopment = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Cloud-native Application Development
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    Our experts seamlessly migrate your applications to the cloud while optimizing their performance. We follow cloud-native principles to increase development speed to build reliable and responsive applications.
                    </h1>
                </div>
                <div className="row mt-xl-5 mt-3">
                    <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization1} alt="" />
                            <div className={style.boldfont}>
                                Enterprise Agile
                            </div>
                            <div className={style.contentfont}>
                                We empower your enterprise to adapt quickly according to market changes and continuously deliver the best application modernization solutions to unlock the potential for innovation and efficiency in this competitive world.
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization2} alt="" />
                            <div className={style.boldfont}>
                                Low-code/No-code
                            </div>
                            <div className={style.contentfont}>
                                Our low-code/no-code solutions simplify the modern app development process at a reduced cost to improve your online presence without compromising the quality and scalability of the application.
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-4 col-md-6 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization3} alt="" />
                            <div className={style.boldfont}>
                                Mainframe Modernization & Migration
                            </div>
                            <div className={style.contentfont}>
                                Our low-code/no-code solutions simplify the modern app development process at a reduced cost to improve your online presence without compromising the quality and scalability of the application.
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-4 col-md-6 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization4} alt="" />
                            <div className={style.boldfont}>
                                NextGen DevOps
                            </div>
                            <div className={style.contentfont}>
                                Our NextGen DevOps utilizes cutting-edge tools and the latest methodologies to deliver high-quality applications consistently. Streamline the collaboration with our developers who automate various workflows to build the app before the deadline.
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-4 col-md-6 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization5} alt="" />
                            <div className={style.boldfont}>
                                Open Source
                            </div>
                            <div className={style.contentfont}>
                                Utilize the full potential of open-source technologies to modernize your applications and our experts build open-source app modernization solutions that are innovative, flexible, and cost-efficient.
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-lg-4 col-md-6 p-xl-4 p-2`}>
                        <div className={`${style.sevicediv}`}>
                            <img src={modernization6} alt="" />
                            <div className={style.boldfont}>
                                App Security and Event Management
                            </div>
                            <div className={style.contentfont}>
                                We offer robust app security and event management services by integrating data protection features into the app. Our programmers follow the best coding standards that help to Detect, respond, and relieve the risks effectively
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NativeAppDevlopment
