import React, { useState } from 'react'
import style from './interview.module.css'
import { Button, Form } from 'react-bootstrap';

const InterviewForm = () => {
    const [errors, setErrors] = useState({ name: '', email: '', phone: '', message: '' });
    const [form, setForm] = useState({ name: '', email: '', phone: '', message: '' });

    // const validateForm = () => {
    //     const { name, email, phone, message } = form;
    //     const newErrors = {};

    //     if (!name || name === "") {
    //         newErrors.name = "Please Enter Name";
    //     }

    //     if (!email || email === "") {
    //         newErrors.email = "Please Enter Email";
    //     } else if (!/\S+@\S+\.\S+/.test(email)) {
    //         newErrors.email = "Please Enter Valid Email";
    //     }

    //     if (!phone || phone === "") {
    //         newErrors.phone = "Please Enter Phone Number";
    //     } else if (!/^\d{10}$/.test(phone)) {
    //         newErrors.phone = "Please Enter Valid Phone Number";
    //     }

    //     if (!message || message === "") {
    //         newErrors.message = "Please Enter Message";
    //     }

    //     return newErrors;
    // };

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const handleOnSubmit = () => {

    }

    return (
        <>
            <div className={style.maindiv}>
                <div className={style.heading}>Schedule A Quick Interview Now To Avail A 15 - days Risk-free Trial</div>
                <div className='d-flex justify-content-center'>
                    <p className={style.content}>Don’t know where to begin? Simply fill out this form and set up a meeting to get started.</p>
                </div>
                <div className=" bg-white">
                    <div className='d-flex justify-content-center'>
                        <Form className={style.form}>
                            <Form.Group controlId='name' className=''>
                                <Form.Control
                                    type="text"
                                    placeholder='Name'
                                    className={`${style.inputfield} form-control mt-xl-5 mt-4`}
                                    value={form.name}
                                    onChange={(e) => setField("name", e.target.value)}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name && <div className="text-danger">{errors.name}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='d-md-flex '>
                                <Form.Group controlId='email' className={`me-md-3 w-100 w-md-50`}>
                                    <Form.Control
                                        type="text"
                                        placeholder='Email'
                                        className={`${style.inputfield} form-control mt-xl-5 mt-4`}
                                        value={form.email}
                                        onChange={(e) => setField("email", e.target.value)}
                                        isInvalid={!!errors.categoryname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='phone' className={`ms-md-3 w-100 w-md-50`}>
                                    <Form.Control
                                        type="text"
                                        placeholder='Phone'
                                        className={`${style.inputfield} form-control mt-xl-5 mt-4`}
                                        value={form.phone}
                                        onChange={(e) => setField("phone", e.target.value)}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <Form.Group controlId='message' className=''>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder='Message'
                                    className={`${style.inputfield} form-control mt-xl-5 mt-4`}
                                    value={form.message}
                                    onChange={(e) => setField("message", e.target.value)}
                                    isInvalid={!!errors.message}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message && <div className="text-danger">{errors.message}</div>}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className='d-flex justify-content-center'>
                                <div className={style.button}>
                                    <Button className={`btn ${style.submitbtn} mt-xl-5 mt-4 px-4`} onClick={handleOnSubmit}>
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InterviewForm