import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const HireReactJs = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Hiring ReactJS Developers in 3 easy steps
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Here you can see the 3 easy steps that you need to follow before hiring ReactJs developers who work dedicatedly on your project.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Appointment of the best ReactJS developers</h1>
                            <p className={style.boxcontent}>After discussing your project requirements, the next step is to appoint the best ReactJs developers for your project who can turn your vision into reality.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6  col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Discuss your project requirements and budget with us</h1>
                            <p className={style.boxcontent}>Our experienced team of ReactJs developers is here to listen to your vision for the solution. We understand that each project is unique and that’s why this consultation stage offers transparency.</p>
                        </div>
                    </div>
                    <div className="col-md-4  col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>On-Time Delivery of the Project</h1>
                            <p className={style.boxcontent}>We understand the importance of timely delivery of the project and our developers ensure that your web application is ready as promised to launch your project without any delays.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireReactJs