import React from 'react';
import style from './footer.module.css';
import logo from '../../assets/logo.png';
// import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <>
      <div className={style.maindiv}>
        <div className={`${style.footer} row`}>
          <div className="col-lg-8 col-12 py-lg-5 pe-5 pt-5">
            <div className='d-flex align-items-center'>
              <div><img src={logo} alt="" className={`${style.profileimg}`} /></div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="153" height="28" viewBox="0 0 153 28" fill="none">
                  <path d="M6.29071 14.3679H0V0H5.59215C6.56359 0 7.40405 0.11643 8.10989 0.349284C8.81573 0.582139 9.33965 0.898672 9.68166 1.29525C10.3402 2.03384 10.6676 2.87066 10.6676 3.80208C10.6676 4.92633 10.3111 5.76315 9.59797 6.3089C9.33601 6.50173 9.15773 6.6218 9.06314 6.6691C8.96854 6.7164 8.79754 6.79644 8.55013 6.90559C9.44152 7.09842 10.151 7.49864 10.6786 8.10625C11.2061 8.71749 11.4681 9.47427 11.4681 10.3766C11.4681 11.3771 11.1261 12.2612 10.4421 13.0289C9.63072 13.9203 8.24815 14.3679 6.29071 14.3679ZM2.42678 5.95962H5.50847C7.26215 5.95962 8.139 5.36293 8.139 4.17319C8.139 3.48918 7.92797 2.99437 7.50228 2.69238C7.0766 2.3904 6.41805 2.24123 5.5303 2.24123H2.42678V5.95962ZM2.42678 12.1266H6.22886C7.12026 12.1266 7.80063 11.9847 8.27361 11.7046C8.7466 11.4244 8.98309 10.8932 8.98309 10.111C8.98309 8.83756 7.96072 8.20085 5.9196 8.20085H2.42678V12.1266Z" fill="#0B618E" />
                  <path d="M25.9779 4.66437C25.9779 7.03658 24.9446 8.5283 22.8743 9.14682L26.6364 14.3679H23.5547L20.1237 9.53612H16.9365V14.3679H14.5098V0H19.8545C22.0484 0 23.6166 0.371113 24.5625 1.1097C25.5049 1.85192 25.9779 3.03439 25.9779 4.66437ZM22.7543 6.70184C23.2491 6.27616 23.4929 5.59579 23.4929 4.65709C23.4929 3.7184 23.2382 3.07441 22.7324 2.72513C22.2267 2.37585 21.3208 2.2012 20.0182 2.2012H16.9365V7.33856H19.9564C21.328 7.33856 22.2595 7.12389 22.7543 6.70184Z" fill="#0B618E" />
                  <path d="M31.5081 11.1006L30.071 14.3679H27.4805L33.8112 0H36.4017L42.7324 14.3679H40.1419L38.7048 11.1006H31.5081ZM37.7152 8.85939L35.1065 2.93979L32.4978 8.85939H37.7152Z" fill="#0B618E" />
                  <path d="M44.7227 0H47.1494V14.3679H44.7227V0Z" fill="#0B618E" />
                  <path d="M61.9065 0H64.3333V14.3679H61.7027L53.5637 3.88576V14.3679H51.137V0H53.5637L61.9101 10.7295V0H61.9065Z" fill="#0B618E" />
                  <path d="M80.2402 14.3679H73.9495V0H79.5416C80.513 0 81.3535 0.11643 82.0593 0.349284C82.7652 0.582139 83.2891 0.898672 83.6311 1.29525C84.2897 2.03384 84.6171 2.87066 84.6171 3.80208C84.6171 4.92633 84.2606 5.76315 83.5474 6.3089C83.2855 6.50173 83.1072 6.6218 83.0126 6.6691C82.918 6.7164 82.747 6.79644 82.4996 6.90559C83.391 7.09842 84.1005 7.49864 84.628 8.10625C85.1556 8.71749 85.4176 9.47427 85.4176 10.3766C85.4176 11.3771 85.0755 12.2612 84.3915 13.0289C83.5838 13.9203 82.1976 14.3679 80.2402 14.3679ZM76.3762 5.95962H79.4579C81.2116 5.95962 82.0885 5.36293 82.0885 4.17319C82.0885 3.48918 81.8774 2.99437 81.4517 2.69238C81.0261 2.3904 80.3675 2.24123 79.4798 2.24123H76.3762V5.95962ZM76.3762 12.1266H80.1783C81.0697 12.1266 81.7501 11.9847 82.2231 11.7046C82.6961 11.4244 82.9326 10.8932 82.9326 10.111C82.9326 8.83756 81.9102 8.20085 79.8691 8.20085H76.3762V12.1266Z" fill="#0B618E" />
                  <path d="M88.4629 0H90.8897V14.3679H88.4629V0Z" fill="#0B618E" />
                  <path d="M105.643 0H108.07V14.3679H105.439L97.3004 3.88576V14.3679H94.8737V0H97.3004L105.647 10.7295V0H105.643Z" fill="#0B618E" />
                  <path d="M114.091 11.1006L112.654 14.3679H110.064L116.395 0H118.985L125.316 14.3679H122.725L121.288 11.1006H114.091ZM120.299 8.85939L117.69 2.93979L115.081 8.85939H120.299Z" fill="#0B618E" />
                  <path d="M138.774 4.66437C138.774 7.03658 137.741 8.5283 135.67 9.14682L139.433 14.3679H136.351L132.92 9.53612H129.733V14.3679H127.306V0H132.651C134.845 0 136.413 0.371113 137.359 1.1097C138.305 1.84828 138.774 3.03439 138.774 4.66437ZM135.55 6.70184C136.045 6.27616 136.289 5.59579 136.289 4.65709C136.289 3.7184 136.034 3.07441 135.529 2.72513C135.023 2.37585 134.117 2.2012 132.814 2.2012H129.733V7.33856H132.753C134.124 7.33856 135.056 7.12389 135.55 6.70184Z" fill="#0B618E" />
                  <path d="M147.655 14.3679H145.188V8.69567L139.844 0H142.496L146.422 6.53448L150.348 0H153L147.655 8.69567V14.3679Z" fill="#0B618E" />
                  <path d="M0.0472412 21.2383H1.07326V27.3107H0.0472412V21.2383Z" fill="#6EB7D7" />
                  <path d="M22.0811 21.2383H23.1071V27.3107H21.9938L18.5519 22.8792V27.3107H17.5259V21.2383H18.5519L22.0811 25.7753V21.2383Z" fill="#6EB7D7" />
                  <path d="M40.5858 22.1952V23.8615H43.2782V24.8075H40.5858V27.3107H39.5598V21.2383H43.6093L43.602 22.1952H40.5858Z" fill="#6EB7D7" />
                  <path d="M64.6644 26.4744C64.0496 27.0747 63.2928 27.3731 62.3868 27.3731C61.4809 27.3731 60.7241 27.0747 60.1092 26.4744C59.4943 25.8741 59.1887 25.1282 59.1887 24.2332C59.1887 23.3381 59.4943 22.5923 60.1092 21.9919C60.7241 21.3916 61.4809 21.0933 62.3868 21.0933C63.2928 21.0933 64.0496 21.3916 64.6644 21.9919C65.2793 22.5923 65.5849 23.3381 65.5849 24.2332C65.5849 25.1246 65.2757 25.8741 64.6644 26.4744ZM63.9113 22.6723C63.4965 22.243 62.9872 22.0283 62.3868 22.0283C61.7829 22.0283 61.2771 22.243 60.8624 22.6723C60.4476 23.1016 60.2402 23.6219 60.2402 24.2332C60.2402 24.8444 60.4476 25.3647 60.8624 25.794C61.2771 26.2233 61.7829 26.438 62.3868 26.438C62.9908 26.438 63.4965 26.2233 63.9113 25.794C64.3261 25.3647 64.5335 24.8444 64.5335 24.2332C64.5335 23.6183 64.3261 23.1016 63.9113 22.6723Z" fill="#6EB7D7" />
                  <path d="M83.7766 22.177V27.3107H82.7506V22.177H80.9095V21.2383H85.6176V22.177H83.7766Z" fill="#6EB7D7" />
                  <path d="M105.767 21.2383V22.2024H102.525V23.8106H105.436V24.7238H102.525V26.3502H105.869V27.3143H101.499V21.2419H105.767V21.2383Z" fill="#6EB7D7" />
                  <path d="M124.726 26.3834C125.079 26.3834 125.385 26.3252 125.64 26.2052C125.894 26.0851 126.16 25.8959 126.44 25.6376L127.102 26.3143C126.458 27.0274 125.68 27.384 124.759 27.384C123.842 27.384 123.078 27.0893 122.474 26.4962C121.87 25.9068 121.565 25.1573 121.565 24.255C121.565 23.3527 121.874 22.5995 122.489 21.9956C123.107 21.3916 123.886 21.0933 124.825 21.0933C125.767 21.0933 126.553 21.4426 127.182 22.1375L126.531 22.8506C126.24 22.5704 125.967 22.3776 125.709 22.2685C125.45 22.1593 125.148 22.1047 124.799 22.1047C124.184 22.1047 123.671 22.3048 123.253 22.7014C122.834 23.098 122.627 23.6037 122.627 24.2222C122.627 24.8408 122.834 25.3538 123.249 25.7685C123.668 26.176 124.159 26.3834 124.726 26.3834Z" fill="#6EB7D7" />
                  <path d="M143.122 27.3107V21.2383H144.148V23.8724H147.164V21.2383H148.19V27.3107H147.164V24.8257H144.148V27.3107H143.122Z" fill="#6EB7D7" />
                </svg>
              </div>
            </div>
            <div>
              <p className={style.footercontent}>Brain Binary Infotech  is committed to delivering innovative, qualitative, and customized IT Solutions using vast experience and superior skills for its Happy Clients.</p>
            </div>
            <div className='d-flex'>
              <a target='_blank' rel="noreferrer" href='https://www.facebook.com/brainbinaryinfotech' className={style.round}>
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.9985 1.9925H6.094V0.0845C5.905 0.0585 5.255 0 4.498 0C2.91851 0 1.83651 0.9935 1.83651 2.8195V4.5H0.0935059V6.633H1.83651V12H3.97351V6.6335H5.646L5.9115 4.5005H3.97301V3.031C3.97351 2.4145 4.1395 1.9925 4.9985 1.9925Z" fill="url(#paint0_linear_69_6760)" />
                  <defs>
                    <linearGradient id="paint0_linear_69_6760" x1="3.09376" y1="0" x2="3.09376" y2="12" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              <a target='_blank' rel="noreferrer" href='https://www.instagram.com/brainbinaryinfotech' className={style.round}>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_69_6764)">
                    <path d="M9.481 0H3.51898C1.57858 0 0 1.57858 0 3.51898V9.4811C0 11.4214 1.57858 13 3.51898 13H9.4811C11.4214 13 13 11.4214 13 9.4811V3.51898C13 1.57858 11.4214 0 9.481 0V0ZM12.2379 9.4811C12.2379 11.0012 11.0012 12.2379 9.481 12.2379H3.51898C1.99881 12.2379 0.762114 11.0012 0.762114 9.4811V3.51898C0.762114 1.99881 1.99881 0.762114 3.51898 0.762114H9.4811C11.0012 0.762114 12.2379 1.99881 12.2379 3.51898V9.4811Z" fill="url(#paint0_linear_69_6764)" />
                    <path d="M6.49989 2.94531C4.53986 2.94531 2.94531 4.53986 2.94531 6.49989C2.94531 8.45993 4.53986 10.0545 6.49989 10.0545C8.45993 10.0545 10.0545 8.45993 10.0545 6.49989C10.0545 4.53986 8.45993 2.94531 6.49989 2.94531ZM6.49989 9.29236C4.96019 9.29236 3.70743 8.03969 3.70743 6.49989C3.70743 4.96019 4.96019 3.70743 6.49989 3.70743C8.03969 3.70743 9.29236 4.96019 9.29236 6.49989C9.29236 8.03969 8.03969 9.29236 6.49989 9.29236Z" fill="url(#paint1_linear_69_6764)" />
                    <path d="M10.1394 1.68311C9.56023 1.68311 9.08911 2.15432 9.08911 2.73344C9.08911 3.31266 9.56023 3.78388 10.1394 3.78388C10.7187 3.78388 11.1899 3.31266 11.1899 2.73344C11.1899 2.15422 10.7187 1.68311 10.1394 1.68311ZM10.1394 3.02166C9.98056 3.02166 9.85122 2.89233 9.85122 2.73344C9.85122 2.57445 9.98056 2.44522 10.1394 2.44522C10.2984 2.44522 10.4278 2.57445 10.4278 2.73344C10.4278 2.89233 10.2984 3.02166 10.1394 3.02166Z" fill="url(#paint2_linear_69_6764)" />
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_69_6764" x1="6.49999" y1="0" x2="6.49999" y2="13" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_69_6764" x1="6.49989" y1="2.94531" x2="6.49989" y2="10.0545" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_69_6764" x1="10.1395" y1="1.68311" x2="10.1395" y2="3.78388" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <clipPath id="clip0_69_6764">
                      <rect width="13" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a target='_blank' rel="noreferrer" href='https://twitter.com/brainbinaryinfotech' className={style.round}>
                <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1.46919C12.5166 1.68125 12.0014 1.82181 11.4644 1.89006C12.0169 1.56019 12.4386 1.04181 12.6368 0.417C12.1217 0.724125 11.5529 0.941063 10.9468 1.06213C10.4577 0.541313 9.76056 0.21875 9.00006 0.21875C7.52456 0.21875 6.33669 1.41638 6.33669 2.88456C6.33669 3.09581 6.35456 3.29894 6.39844 3.49231C4.18275 3.38425 2.22219 2.32231 0.905125 0.704625C0.675187 1.10356 0.540313 1.56019 0.540313 2.05175C0.540313 2.97475 1.01562 3.79294 1.72413 4.26662C1.29594 4.2585 0.875875 4.13419 0.52 3.93837C0.52 3.9465 0.52 3.95706 0.52 3.96762C0.52 5.26275 1.44381 6.3385 2.65525 6.58631C2.43831 6.64563 2.20187 6.67406 1.9565 6.67406C1.78587 6.67406 1.61362 6.66431 1.45194 6.62856C1.79725 7.684 2.77713 8.45994 3.94225 8.48512C3.0355 9.19444 1.88419 9.62181 0.637812 9.62181C0.41925 9.62181 0.209625 9.61206 0 9.58525C1.18056 10.3466 2.57969 10.7812 4.0885 10.7812C8.99275 10.7812 11.674 6.71875 11.674 3.19737C11.674 3.07956 11.6699 2.96581 11.6643 2.85287C12.1932 2.4775 12.6376 2.00869 13 1.46919Z" fill="url(#paint0_linear_69_6774)" />
                  <defs>
                    <linearGradient id="paint0_linear_69_6774" x1="6.5" y1="0.21875" x2="6.5" y2="10.7812" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/in/brainbinaryinfotech" className={style.round}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_69_6778)">
                    <path d="M10.9973 11V10.9996H11.0001V6.96531C11.0001 4.99173 10.5752 3.47144 8.26795 3.47144C7.15879 3.47144 6.41445 4.0801 6.11058 4.65714H6.07849V3.65569H3.89087V10.9996H6.16879V7.36314C6.16879 6.40569 6.35029 5.47985 7.536 5.47985C8.70429 5.47985 8.7217 6.57252 8.7217 7.42456V11H10.9973Z" fill="url(#paint0_linear_69_6778)" />
                    <path d="M0.181396 3.65601H2.46206V10.9999H0.181396V3.65601Z" fill="url(#paint1_linear_69_6778)" />
                    <path d="M1.32092 0C0.591708 0 0 0.591708 0 1.32092C0 2.05013 0.591708 2.65421 1.32092 2.65421C2.05013 2.65421 2.64183 2.05013 2.64183 1.32092C2.64138 0.591708 2.04967 0 1.32092 0V0Z" fill="url(#paint2_linear_69_6778)" />
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_69_6778" x1="7.44547" y1="3.47144" x2="7.44547" y2="11" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_69_6778" x1="1.32173" y1="3.65601" x2="1.32173" y2="10.9999" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_69_6778" x1="1.32092" y1="0" x2="1.32092" y2="2.65421" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                    <clipPath id="clip0_69_6778">
                      <rect width="11" height="11" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </a>
              <a target='_blank' rel="noreferrer" href='https://www.behance.net/BrainbinaryInfotech' className={style.round}>
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.88477 1.73828C7.16602 2.13086 7.30664 2.60547 7.30664 3.15625C7.30664 3.71875 7.16602 4.17578 6.87891 4.52148C6.7207 4.71484 6.48047 4.89062 6.16992 5.04883C6.64453 5.22461 7.00195 5.49414 7.24219 5.86914C7.48242 6.24414 7.60547 6.69531 7.60547 7.22852C7.60547 7.7793 7.4707 8.27148 7.18945 8.70508C7.01367 8.99219 6.79688 9.23828 6.5332 9.43164C6.24023 9.66016 5.88867 9.8125 5.48438 9.90039C5.08008 9.98242 4.64648 10.0234 4.17188 10.0234H0V0.748047H4.48242C5.60742 0.765625 6.41016 1.09375 6.88477 1.73828ZM1.8457 2.35938V4.4043H4.10156C4.50586 4.4043 4.82812 4.32812 5.08008 4.17578C5.33203 4.02344 5.45508 3.74805 5.45508 3.36133C5.45508 2.92773 5.29102 2.64648 4.95703 2.50586C4.66992 2.41211 4.30664 2.35938 3.86133 2.35938H1.8457ZM1.8457 5.93359V8.40625H4.10156C4.50586 8.40625 4.81641 8.35352 5.03906 8.24219C5.44922 8.03711 5.64844 7.65625 5.64844 7.08789C5.64844 6.60742 5.45508 6.27344 5.05664 6.0918C4.83398 5.99219 4.5293 5.93945 4.13086 5.93359H1.8457ZM13.207 3.30273C13.6875 3.51367 14.0801 3.85352 14.3906 4.31641C14.6719 4.7207 14.8535 5.19531 14.9355 5.73437C14.9824 6.05078 15.0059 6.50195 14.9941 7.09375H10.002C10.0312 7.7793 10.2656 8.26562 10.7168 8.54101C10.9922 8.71094 11.3203 8.79883 11.707 8.79883C12.1113 8.79883 12.4453 8.69336 12.7031 8.48242C12.8438 8.37109 12.9668 8.21289 13.0723 8.00781H14.9004C14.8535 8.41211 14.6309 8.82812 14.2383 9.25C13.623 9.91797 12.7676 10.252 11.6602 10.252C10.7461 10.252 9.94336 9.9707 9.24609 9.4082C8.54883 8.8457 8.19727 7.93164 8.19727 6.66601C8.19727 5.47656 8.51367 4.56836 9.14062 3.93555C9.77344 3.30273 10.5879 2.98633 11.5898 2.98633C12.1934 2.98047 12.7324 3.08594 13.207 3.30273ZM10.5293 4.84961C10.2773 5.11328 10.1191 5.46484 10.0488 5.91016H13.1367C13.1016 5.43555 12.9434 5.07227 12.6621 4.82617C12.375 4.58008 12.0176 4.45703 11.5957 4.45703C11.1387 4.45703 10.7812 4.5918 10.5293 4.84961ZM13.5586 1.18164H9.58594V2.33594H13.5586V1.18164Z" fill="url(#paint0_linear_69_6785)" />
                  <defs>
                    <linearGradient id="paint0_linear_69_6785" x1="7.49856" y1="0.748047" x2="7.49856" y2="10.252" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              <a target='_blank' rel="noreferrer" href="https://dribbble.com/BrainbinaryInfotech" className={style.round}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.66667 0C2.98467 0 0 2.98467 0 6.66667C0 10.3487 2.98467 13.3333 6.66667 13.3333C10.3487 13.3333 13.3333 10.3487 13.3333 6.66667C13.3333 2.98467 10.3487 0 6.66667 0ZM6.66667 0.966001C8.119 0.966001 9.44467 1.50933 10.4513 2.40333C9.71167 3.379 8.58801 4.02867 7.46201 4.46833C6.84834 3.34567 6.09267 2.162 5.32367 1.125C5.754 1.021 6.20367 0.966001 6.66667 0.966001ZM4.24133 1.50633C4.98433 2.618 5.70434 3.649 6.36234 4.816C4.69867 5.247 2.81934 5.50567 1.08301 5.51C1.44901 3.734 2.642 2.25933 4.24133 1.50633ZM11.0837 3.06267C11.879 4.03567 12.358 5.27667 12.3673 6.629C11.0487 6.36933 9.73701 6.301 8.40201 6.43567C8.25167 6.06133 8.07 5.706 7.89567 5.32233C9.04667 4.858 10.2773 4.06333 11.0837 3.06267ZM6.82534 5.698C6.968 6.00167 7.13334 6.32333 7.28834 6.65233C5.43167 7.471 3.457 8.565 2.43167 10.483C1.52067 9.47267 0.966003 8.134 0.966003 6.66667C0.966003 6.611 0.967002 6.55533 0.968669 6.5C2.957 6.49033 4.90867 6.24167 6.82534 5.698ZM12.348 7.14133C12.3463 7.16333 12.3443 7.18567 12.342 7.20767C12.3443 7.18567 12.3463 7.16367 12.348 7.14133ZM9.83767 7.25233C10.6663 7.24967 11.5227 7.36533 12.2963 7.569C12.0433 9.161 11.1307 10.534 9.84734 11.398C9.54134 10.0063 9.24968 8.67467 8.73401 7.332C9.08968 7.279 9.461 7.25333 9.83767 7.25233ZM12.326 7.35567C12.322 7.38833 12.3183 7.42033 12.3137 7.45267C12.3183 7.42 12.3223 7.388 12.326 7.35567ZM7.67901 7.59267C8.20467 8.95 8.617 10.4533 8.89367 11.9163C8.20933 12.207 7.457 12.3677 6.66667 12.3677C5.34833 12.3677 4.13434 11.9197 3.16867 11.168C4.201 9.493 5.80001 8.22567 7.67901 7.59267Z" fill="url(#paint0_linear_69_6791)" />
                  <defs>
                    <linearGradient id="paint0_linear_69_6791" x1="6.66667" y1="0" x2="6.66667" y2="13.3333" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#68B1D2" />
                      <stop offset="1" stopColor="#0B618E" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 pt-lg-5 pt-3 mt-3 d-lg-flex">
            <div className={`${style.hrsection} mb-4`}>
              <div>
                <h1 className={style.heading}>Inquiry</h1>
              </div>
              <div className='d-flex mt-3'>
                <div className='me-2'>
                  <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.381 15.1904V4.04754C25.381 4.04754 14.1144 11.9094 13.0187 12.3174C11.9415 11.928 0.619141 4.04754 0.619141 4.04754V15.1904C0.619141 16.738 0.947236 17.0475 2.47628 17.0475H23.5239C25.0895 17.0475 25.381 16.7758 25.381 15.1904ZM25.3631 1.86292C25.3631 0.735633 25.0344 0.333252 23.5239 0.333252H2.47628C0.922474 0.333252 0.619141 0.816109 0.619141 1.94278L0.637712 2.11611C0.637712 2.11611 11.8233 9.81706 13.0187 10.238C14.2815 9.74897 25.381 1.94278 25.381 1.94278L25.3631 1.86292Z" fill="url(#paint0_linear_69_6740)" />
                    <defs>
                      <linearGradient id="paint0_linear_69_6740" x1="13.0001" y1="0.333252" x2="13.0001" y2="17.0475" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#68B1D2" />
                        <stop offset="1" stopColor="#0B618E" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div>
                  <p className={style.content}>
                    <a href="mailto:brainbinaryinfotech@gmail.com">brainbinaryinfotech@gmail.com</a>
                  </p>
                </div>
              </div>
              <div className='d-flex mt-2'>
                <div className='me-2'>
                  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.9358 11.9818L13.4423 12.4723C13.4423 12.4723 12.2695 13.6385 9.06828 10.4554C5.86705 7.27234 7.03984 6.10619 7.03984 6.10619L7.35054 5.79724C8.11602 5.03615 8.18818 3.81423 7.5203 2.92218L6.15428 1.09741C5.32772 -0.00667563 3.73054 -0.152525 2.78315 0.789477L1.08275 2.48022C0.612999 2.94731 0.298204 3.5528 0.336381 4.22449C0.434043 5.94288 1.21152 9.64017 5.54989 13.9539C10.1505 18.5284 14.4673 18.7102 16.2326 18.5456C16.7909 18.4936 17.2765 18.2092 17.6678 17.8201L19.2068 16.29C20.2456 15.257 19.9526 13.4862 18.6235 12.7637L16.5538 11.6386C15.681 11.1642 14.6179 11.3035 13.9358 11.9818Z" fill="url(#paint0_linear_69_6748)" />
                    <defs>
                      <linearGradient id="paint0_linear_69_6748" x1="10.0833" y1="0.166748" x2="10.0833" y2="18.5834" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#68B1D2" />
                        <stop offset="1" stopColor="#0B618E" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div>
                  <p className={style.content}>
                    <a href="tel:+918460484914">+91 84604 84914</a>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className={`${style.copyright} justify-content-center text-center`}>
          <h1 className={`${style.content}`}>© 2024 All rights reserved by Brain Binary Infotech.</h1>
        </div>
      </div>
    </>
  )
}

export default Footer;
