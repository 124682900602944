import React from 'react'
import style from '../workflow.module.css'
import reactjsflowimage from '../../../assets/reactjs/flowimage.png'

const FullStackWorkFlow = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our Full Stack Development Workflow
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We use cutting-edge technologies, strong frameworks, and best industry practices to build and deliver your Full Stack application on schedule. Here is our Full Stack development workflow
                    </h1>
                </div>
                <div className={style.flowchart}>
                    <div className={style.box1}>
                        <div className={style.subheading}>Design</div>
                        <div className={style.subcontent}>From our expertise, we know how individuals communicate with Full Stack apps and employ the best practices to design effective user flows. To develop aesthetically appealing, easy, and uniform apps, our designers use proven UI trends.</div>
                    </div>
                    <div className={style.box2}>
                        <div className={style.subheading}>Deliver</div>
                        <div className={style.subcontent}>Our developers rigorously test the whole platform to ensure that it provides a pleasant customer experience and satisfies the demands of our clients.</div>
                    </div>
                    <div className={style.box3}>
                        <div className={style.subheading}>Build</div>
                        <div className={style.subcontent}>During this phase, our dedicated ReactJs developers write well-documented code to create dynamic user interfaces to ensure that your web application performs well and is responsive across devices.When creating Full Stack design, We placed top attention to high-load and simple flexibility for the future. Our programmers have the knowledge and experience required to create a profitable Full Stack solution.</div>
                    </div>
                    <div className={style.box4}>
                        <div className={style.subheading}>Discover</div>
                        <div className={style.subcontent}>Our researchers and business strategists work with you to investigate, develop, and document the business value proposition and operational core of your Full Stack service.</div>
                    </div>
                    <div className='text-center'>
                        <img src={reactjsflowimage} className={style.flowimage} alt="ReactJs Flow" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullStackWorkFlow