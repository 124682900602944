import React from 'react'
import style from '../service.module.css'
import product1 from '../../../assets/product-engineering/product1.png'
import product2 from '../../../assets/product-engineering/product2.png'
import product3 from '../../../assets/product-engineering/product3.png'
import product4 from '../../../assets/product-engineering/product4.png'
import product5 from '../../../assets/product-engineering/product5.png'
import product6 from '../../../assets/product-engineering/product6.png'

const ProductTechnology = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Services Our IOS Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Hire iPhone developers from Brain Binanry Infotech if you are trying to get high-performance native or cross-platform iOS solutions.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Our Software Product Engineering Technology Stack
                        </div>
                        <div className={style.contentfont}>
                            At Brain Binary Infotech we have a team of proficient developers that uses some of the latest technology stacks for your project. We understand the requirements of your project and then choose the appropriate tech stack to build fully functional solutions.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Mobile & Web
                        </div>
                        <div className={style.contentfont}>
                            We have a team of experts who create different types of mobile & web applications. Brain Binary Infotech is a web development company that creates premium websites and customized web
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Content Management
                        </div>
                        <div className={style.contentfont}>
                            We have been using different types of content management systems like WordPress and Drupal to handle the content that is stored in a database and displayed in a presentation layer.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Big Data
                        </div>
                        <div className={style.contentfont}>
                            Our programmers have knowledge of big data technologies like Hadoop, Spark, and Cassandra. We also provide various product engineering services related to big data.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Test Automation
                        </div>
                        <div className={style.contentfont}>
                            We use automatic testing tools like Appium, Cucumber, See Test, and Selenium to make sure that the software is bug-free and runs smoothly.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={product6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Cloud Consulting & DevOps
                        </div>
                        <div className={style.contentfont}>
                            We use cloud platforms like Docker, Chef, Puppet, Jenkins, AWS, and Microsoft Azure to test and build different types of applications.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductTechnology