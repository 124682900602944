import React from 'react'
import style from '../numberbackground.module.css'

const   ItStaffAugmentationServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Benefits of application modernization
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    At Brain Binary Infotech we provide the finest application modernization services to automate processes of different types of businesses. We provide some of the finest microservices so that clients can use our service of application management and enjoy some benefits.
                    </h1>
                </div>
                <div className='text-center'>
                    <button className={`btn ${style.submitbtn} mt-5 px-4`} >
                        Talk To Our Experts
                    </button>
                </div>
                <div>
                    <div className="row mt-4">
                         <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>01</h1>
                                    <p className={style.numbercontent}>No Recruitment Hassles</p>
                                </div>
                                <p className={style.boxcontent}>By using IT augmentation services, you can avoid the trouble of looking for highly qualified development services. Get information about our experienced professionals to get a transparent recruitment process.</p>
                            </div>
                        </div>
                         <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>02</h1>
                                    <p className={style.numbercontent}>Lower Operational Costs</p>
                                </div>
                                <p className={style.boxcontent}>Easily lower your company’s expenses by minimizing the price of office space, equipment, and taxes. Spend your money wisely on the things that can be most beneficial for your business.</p>
                            </div>
                        </div>
                         <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>03</h1>
                                    <p className={style.numbercontent}>Aggressive Deadlines</p>
                                </div>
                                <p className={style.boxcontent}>Increase your technical skill sets when your team is working under pressure. Our development team works constantly to complete the project on deadlines.</p>
                            </div>
                        </div>
                         <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>04</h1>
                                    <p className={style.numbercontent}>Full Control</p>
                                </div>
                                <p className={style.boxcontent}>Take part in our screening process to hire your desired team members. Keep track of all changes and evaluate the success of your project.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ItStaffAugmentationServices
