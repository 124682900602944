import React, { useState } from 'react'
import style from './appdevlopment.module.css'
import roundspring from '../../assets/roundspring.png'
import appdevlopment1 from '../../assets/app-devlopment/appdevlopment1.png'
import appdevlopment2 from '../../assets/app-devlopment/appdevlopment2.png'
import appdevlopment3 from '../../assets/app-devlopment/appdevlopment3.png'
import InterviewForm from '../../components/interviewform/InterviewForm'
import ClientSlider from '../../components/clientslider/ClientSlider'
import ContactUs from '../../components/contactus/ContactUs'
import AppDevlopmentServices from '../../components/appdevlopmenrservices/AppDevlopmentServices'
import MobileWorkFlow from '../../components/webmobileworkflow/mobile/MobileWorkFlow'
import GetQuote from '../../components/getquote/GetQuote'

const AppDevlopment = () => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-xl-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Best </span>
                            <span className={style.development}>Mobile App  </span>
                            <span className={style.company}>Development Company</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>We are a leading mobile app development company that develops custom mobile applications for iOS and Android. Our developers will use AI and Machine Learning technologies to deliver the best mobile applications.
                                </p>
                            </div>
                            <div>
                                <button className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`} onClick={() => setShowModal(true)}>Request A Quote</button>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 d-xl-inline d-flex justify-content-center text-end">
                        <img src={appdevlopment1} className={style.appimage} alt="App Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowcolor}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        World-Class Mobile App Development Services For Your Business
                    </div>
                    <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className=''>
                            <p className={style.spancontent}>Many sectors have begun to use mobile apps for their businesses to stand out in the market and give the most excellent digital experience to their consumers across all mobile devices, thanks to the steady rise of smartphones. The developers from our Mobile app development company try to deliver strategically designed and innovative mobile applications.
                            </p>
                            <p className={style.spancontent}>When it comes to Brain Binary Infotech, we provide high-quality Mobile App Designing in the United States at a reasonable price. We do everything possible to help your business stay relevant in the digital age, whether Android app creation, iPhone app development, or iPad app development.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 text-end d-xl-inline d-flex justify-content-center">
                        <img src={appdevlopment2} className={style.mapimage} alt="App Devlopment" />
                    </div>
                </div>
                <AppDevlopmentServices />
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Why choose Brain Binary Infotech for Mobile App Development??
                    </div>
                    <div className="row mt-md-5 mt-4">
                        <div className="col-lg-5 col-12 py-xl-5 pe-xl-5 d-xl-inline d-flex justify-content-center">
                            <img src={appdevlopment3} alt="" className={style.chooseappimage} />
                        </div>
                        <div className="col-lg-7 col-12 p-xl-5 p-lg-3 p-2 mt-lg-0 mt-4 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                The usage of mobile apps has inevitably surged across the globe. To cope with this, we become your design and development accelerator for brilliant mobile applications. specializing in mobile app development for iPhone, iPad, Android, and other mobile devices.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                We are a top-notch mobile app development company that has a team of experienced mobile app developers who can develop a mobile application that will meet the client’s requirements. Our programmers can design highly customized mobile applications for consumers and businesses with the help of various tools.
                            </div>
                        </div>
                    </div>
                </div>
                <InterviewForm />
                <MobileWorkFlow />
                <div className='mb-lg-5 pb-lg-5 pb-md-4 mb-md-4 mb-3 pb-0'>
                    <ClientSlider />
                </div>
                <ContactUs />
                <GetQuote showModal={showModal} closeModal={() => setShowModal(false)} />
            </div >
        </>
    )
}

export default AppDevlopment