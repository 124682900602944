import React from 'react'
import style from './fullstack.module.css'
import HireDevlopers from '../../components/hiredevlopers/HireDevlopers'
import ContactUs from '../../components/contactus/ContactUs'
import fullstackimage from '../../assets/fullstack/fullstackimage.png'
import FullStackWorkFlow from '../../components/workflow/fullstack/FullStackWorkFlow'
import FullStackServices from '../../components/services/fullstack/FullStackServices'
import HireFullStack from '../../components/hiredevloperway/fullstack/HireFullStack'

const FullStack = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Hire </span>
                            <span className={style.development}>Full Stack </span>
                            <span className={style.company}> Developers  </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Hire Full stack developers from us who have hands-on experience with the latest programming languages and frameworks to build frontend as well as backend of the web and mobile applications. We have a team of 25+ experienced programmers who create outstanding and fully-featured database management software so that you can get maximum ROI on your investment.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-md-0 mt-3">
                        <HireDevlopers />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Hire Dedicated Full Stack Developer
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-lg-5 col-12 d-xl-inline d-flex justify-content-center">
                            <img src={fullstackimage} alt="Full Stack" className={style.fullstackimage} />
                        </div>
                        <div className="col-lg-7 col-12 mt-lg-0 mt-3 p-lg-3 p-1 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                Hire full stack software developers from us who create different types of mobile, web, or desktop applications from scratch. They utilize their knowledge of software development, databases, servers, MVC, APIs, and third-party plugins to create the most attractive and dynamic web solutions. Our programmers use tools and frameworks like NodeJs, Rapid.Js, MongoDB, AngularJS, React, jQuery, and many more to provide different types of full stack development solutions.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                Connect with our team of full stack developers that create user-engaging and appealing applications for your business to stay ahead of your competitors.
                            </div>
                        </div>
                    </div>
                </div>
                <FullStackServices />
                <FullStackWorkFlow />
                <HireFullStack />
                <ContactUs />
            </div >
        </>
    )
}

export default FullStack