import React from 'react'
import style from './itstaffprocess.module.css'
import uiuxflowimage from '../../../assets/uiux/uiuxworkflow.png'

const ItStaffProcess = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our IT Staff Augmentation Process
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We have vast experience in software development and our developers implement advanced methods to improve clients’ IT efficiency. Our IT staff augmentation process is simple as we implement the appropriate project management methods to deliver the solutions.
                    </h1>
                </div>
                <div className={style.uiuxflowchart}>
                    <div className={style.uiuxbox1}>
                        <div className={style.subheading}>Choose Developers</div>
                    </div>
                    <div className={style.uiuxbox2}>
                        <div className={style.subheading}>Manage extended<br /> team</div>
                    </div>
                    <div className={style.uiuxbox3}>
                        <div className={style.subheading}>Tell Your<br /> Requirements</div>
                    </div>
                    <div className={style.uiuxbox4}>
                        <div className={style.subheading}>Onboard remote<br /> programmers</div>
                    </div>
                    <div className={style.uiuxbox5}>
                        <div className={style.subheading}>Get your project<br /> delivered</div>
                    </div>
                    <div className='text-center'>
                        <img src={uiuxflowimage} className={style.flowimage} alt="ReactJs Flow" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItStaffProcess