import React from 'react'
import style from './outsourcingservices.module.css'
import agmentedteam1 from '../../assets/itstaff/agmentedteam1.png'
import agmentedteam2 from '../../assets/itstaff/agmentedteam2.png'
import agmentedteam3 from '../../assets/itstaff/agmentedteam3.png'

const OutsourcingServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`${style.heading} ${style.headingborder}`}>
                    Our Software Outsourcing Services
                </div>
                <div className='d-flex justify-content-center pb-lg-4'>
                    <h1 className={style.content}>
                        We have a long track record of providing Software Outsourcing Services. At Brain Binary Infotech we follow a discovery, ideation, and elaboration approach to increase stability and reduce risk while providing various outsourcing solutions for your projects.
                    </h1>
                </div>
                <div className='overflow-hidden'>
                    <div className={`row mt-lg-5 mt-3 ${style.borderrow}`}>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam1} alt="" />
                            <div className={style.boldfont}>
                                Custom Software Development
                            </div>
                            <div className={style.contentfont}>
                                Leverage Brain Binary Infotech’s expertise to create most dependable and flexible apps by customizing your software development requirements. We provide various outsourcing custom software development services like app integration, maintenance, and cross-platform app development
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam2} alt="" />
                            <div className={style.boldfont}>
                                Web Development
                            </div>
                            <div className={style.contentfont}>
                                We create the most productive web applications as per the client’s requirements. Our services update the current systems, add innovative features, and the latest security measures, and create strong architectural designs with user-friendly interfaces.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam3} alt="" />
                            <div className={style.boldfont}>
                                Mobile Application
                            </div>
                            <div className={style.contentfont}>
                                Take advantage of mobile app development’s full potential with our capacity to create mobile apps for different types of platforms by utilizing the latest technology and systematic methods.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam1} alt="" />
                            <div className={style.boldfont}>
                                Front end development
                            </div>
                            <div className={style.contentfont}>
                                With proper guidance across the whole development process, from the design conception and analysis phase to deployment, our front-end development services assist various businesses to meet their specific goals.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam2} alt="" />
                            <div className={style.boldfont}>
                                Saas Application Development
                            </div>
                            <div className={style.contentfont}>
                                Our expert software engineers are using cloud platform services like Amazon web service, Jenkins, Google Cloud, Docket, and Azure to provide SaaS app development services including the design and maintenance of the SaaS app.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam3} alt="" />
                            <div className={style.boldfont}>
                                E-commerce Application
                            </div>
                            <div className={style.contentfont}>
                                Get the best outsourcing software development services from us because we have years of experience to develop customized eCommerce applications that are fully functional and offer cross-platform facilities.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OutsourcingServices