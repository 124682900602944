import React from 'react'
import style from './digitalmarketing.module.css'
import ContactUs from '../../components/contactus/ContactUs'
import digitalmarketing1 from '../../assets/digitalmarketing/digitalmarketing1.png'
import digitalmarketing2 from '../../assets/digitalmarketing/digitalmarketing2.png'
import digitalmarketing3 from '../../assets/digitalmarketing/digitalmarketing3.png'
import DigitalMarketingServices from '../../components/services/digitalmarketing/DigitalMarketingServices'
import Experience from '../../components/expireance/Experience'
import IndustryServe from '../../components/industryserve/IndustryServe'

const DigitalMarketing = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Best </span>
                            <span className={style.development}>Digital Marketing </span>
                            <span className={style.company}> Services  </span>
                            <span className={style.software}>Company </span>

                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>We offer dynamic, versatile, and innovative digital marketing services for clients. Our experienced team uses Search Engine Optimization (SEO) and various marketing skills to attract more customers to the client’s website.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 text-end  d-xl-inline d-flex justify-content-center ">
                        <img src={digitalmarketing1} alt="" className={style.digitalmarketingimage} />
                    </div>
                </div>
                <div className={`${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Why Important Digital Marketing For Your Business?
                    </div>
                    <div className="row mt-md-5 mt-3">
                        <div className="col-xl-5 col-md-6 col-12 p-xl-5">
                            <img src={digitalmarketing2} alt="" className={style.digitalmarketingimage} />
                        </div>
                        <div className="col-xl-7 col-md-6 col-12 p-xl-5 p-lg-3 p-1 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                BrainBinary Infotech is the world's fastest-growing digital marketing company, with offices in the United States, India, and other nations. Our digital marketing team creates a customized business plan based on your company's type and existing position in the market. We are India's most prominent digital marketing company, specializing in sponsored search management, search engine optimization, and social media marketing.
                            </div>
                            <div className={`mt-4 ${style.content}`}>
                                We are a unique combination of old-school branding concepts of being noticed and acting quickly and a firm that sticks to creative approaches while keeping up with the technology.
                            </div>
                        </div>
                    </div>
                </div>
                <DigitalMarketingServices />
                <div className={`mt-xl-5 mt-4 ${style.rowpadding}`}>
                    <div className={`${style.heading} ${style.headingborder}`}>
                        Why Choose BrainBinary Infotech For Digital Marketing
                    </div>
                    <div className="row mt-xl-5 mt-4">
                        <div className="col-xl-5 col-md-6 col-12 py-xl-5 pe-xl-5">
                            <img src={digitalmarketing3} alt="Digital Marketing" className={style.digitalmarketingimage} />
                        </div>
                        <div className="col-xl-7 col-md-6 col-12 p-xl-5 p-lg-3 p-2 mt-md-0 mt-4 d-flex flex-column justify-content-center">
                            <div className={style.content}>
                                BrainBinary Infotech is a prominent and fastest-growing Also added internal link. Digital marketing company in the United States, providing comprehensive digital marketing services to help your company succeed in the digital age. Our skilled team of digital marketers has extensive knowledge and skills to apply the most commonly searched keywords linked to the services and optimize websites with search engine optimization (SEO) to rank higher on leading search engines. We provide personalized digital marketing services to assist your company in achieving the highest possible ranking in the market.

                            </div>
                        </div>
                    </div>
                </div>
                <IndustryServe />
                <div className='mb-5 pb-xl-5'>
                    <Experience />
                </div>
                <ContactUs />
            </div >
        </>
    )
}

export default DigitalMarketing