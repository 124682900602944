import React from 'react'
import style from './modernizationservices.module.css'
import roundspring from '../../assets/roundspring.png'
import ContactUs from '../../components/contactus/ContactUs'
import modernizationimage from '../../assets/modernization-services/modernizationimage1.png'
import NativeAppDevlopment from '../../components/nativeappdevlopment/NativeAppDevlopment'
import AppModernization from '../../components/numberbackground/appmodernization/AppModernization'
import GetFreeConsultancy from '../../components/getfreeconsultancy/GetFreeConsultancy'
import Experience from '../../components/expireance/Experience'
import WordsFromClientele from '../../components/wordsfromClientele/WordsFromClientele'
import IndustryServe from '../../components/industryserve/IndustryServe'

const ModernizationServices = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-xl-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.software}>Application </span>
                            <span className={style.development}>Modernization </span>
                            <span className={style.company}>Services</span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>In this constantly evolving world, get the best Application modernization services from us that help your business to grow in this competitive market. Brain Binary Infotech helps various businesses to re-architect and integrate third-party plugins in their applications.
                                </p>
                            </div>
                            <div>
                                <a href='#getfreeconsultancy' className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`}>Start Outsourcing Process</a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-4 text-end d-xl-inline d-flex justify-content-center position-relative">
                        <img src={modernizationimage} className={style.modernizationimage} alt="Web Devlopment" />
                    </div>
                </div>
                <NativeAppDevlopment />
                <AppModernization />
                <GetFreeConsultancy />
                <Experience />
                <IndustryServe />
                <WordsFromClientele />
                <ContactUs />
            </div >
        </>
    )
}

export default ModernizationServices