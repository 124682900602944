import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/itconsulting/service1.png'
import service2 from '../../../assets/itconsulting/service2.png'
import service3 from '../../../assets/itconsulting/service3.png'
import service4 from '../../../assets/itconsulting/service4.png'
import service5 from '../../../assets/itconsulting/service5.png'
import service6 from '../../../assets/itconsulting/service6.png'
import service7 from '../../../assets/itconsulting/service7.png'
import service8 from '../../../assets/itconsulting/service8.png'
import service9 from '../../../assets/itconsulting/service9.png'

const ConsultingServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our IT Consulting Services
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            IT Assessment
                        </div>
                        <div className={style.contentfont}>
                            At Brain Binary Infotech we have a team of experts that find the possibilities to improve security, and efficiency, and reduce the cost of your IT structure.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            IT Strategy Consulting
                        </div>
                        <div className={style.contentfont}>
                            Get the finest IT strategies to line up your business in such a position to handle application integrations, databases, development infrastructure, and even data warehouses.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            IT Operation Consulting
                        </div>
                        <div className={style.contentfont}>
                            We provide IT operation consultation service that helps to increase efficiency, and speed, and reduce the cost of IT operations by automating them.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Technology Consulting
                        </div>
                        <div className={style.contentfont}>
                            We integrate digital technologies like cloud computing, cybersecurity, big data, and machine learning to bring innovative changes to the business model.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            IT Project & Program Management
                        </div>
                        <div className={style.contentfont}>
                            Our IT consultation service helps to collaborate with various activities within one or multiple projects to get high-quality results with better project workflow.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Application Support
                        </div>
                        <div className={style.contentfont}>
                            Our main goal is to provide perfect application support services to keep your business applications fully functional and updated with the latest technology.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Digital Transformation Consulting
                        </div>
                        <div className={style.contentfont}>
                            We provide the best IT consulting service to improve the efficiency of your business by planning and giving guidance for digital transformation.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Application implementation
                        </div>
                        <div className={style.contentfont}>
                            Our experts analyze your business requirements and implement the best business software that can bring value and provide support & maintenance facilities.
                        </div>
                    </div>
                </div>
               <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Application Modernization
                        </div>
                        <div className={style.contentfont}>
                            We provide optimum application modernization services to increase the overall value of the existing applications of your business.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConsultingServices