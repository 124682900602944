import React from 'react'
import style from '../numberbackground.module.css'

const WhyBBProductEngi = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Why Choose Brain Binary Infotech for Software Product Engineering?
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    At Brain Binary Infotech, our team collaborates with your company to understand the requirements of your business. Our programmers are well aware of the problems and difficulties that clients can experience. That’s why our experts try to deliver the best solutions to increase adaptability and provide the best customer experience. 
                    </h1>
                </div>
                <div>
                    <div className="row mt-4">
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>01</h1>
                                    <p className={style.numbercontent}>Time-To-Market</p>
                                </div>
                                <p className={style.boxcontent}>We follow an iterative approach to increase the development speed of a better product by optimizing all development processes.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>02</h1>
                                    <p className={style.numbercontent}>Time-To-Market</p>
                                </div>
                                <p className={style.boxcontent}>Get in touch with us to get software product development services at affordable prices. We have a team of professionals that provide migration, testing, maintenance, and support services along with software development.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>03</h1>
                                    <p className={style.numbercontent}>End-to-end rapid digital transformation</p>
                                </div>
                                <p className={style.boxcontent}>We provide different types of engagement models and by using some of the reusable frameworks and libraries we can deliver your product quickly.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>04</h1>
                                    <p className={style.numbercontent}>Dedicated Center of Competency</p>
                                </div>
                                <p className={style.boxcontent}>We are always ready to learn something new and that’s why we also provide fully functional web, mobile, and cloud-based digital solutions.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>05</h1>
                                    <p className={style.numbercontent}>High-quality applications</p>
                                </div>
                                <p className={style.boxcontent}>As a leading software product engineering Services Company, we try to deliver customer-oriented high-quality applications.</p>
                            </div>
                        </div>
                       <div className="col-md-6 col-12 px-xl-4 px-lg-3 px-2 py-3">
                            <div className={style.box}>
                                <div className='position-relative'>
                                    <h1 className={style.number}>06</h1>
                                    <p className={style.numbercontent}>Agile Methodology</p>
                                </div>
                                <p className={style.boxcontent}>We follow agile methodology and break the project into several phases to deliver the solution at a faster rate for the customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WhyBBProductEngi