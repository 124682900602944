import React, { useState } from 'react'
import style from './ourtechnologies.module.css'
import { useEffect } from 'react';
import axios from 'axios';

const OurTechnologies = () => {

    const [Items, setItems] = React.useState([]);
    const [Categories, setCategories] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const Get_Items = () => {
        axios.get("https://api.brainbinaryinfotech.com/ourTechnologyitems/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setItems(res.data.TECH_ITEMS);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Get_Categories = () => {
        axios.get("https://api.brainbinaryinfotech.com/ourTechnologycategories/")
            .then((res) => {
                setCategories(res.data.TECH_CATEGORIES);
                // Initialize selectedCategory with the first category's name
                setSelectedCategory(res.data.TECH_CATEGORIES[0].technology_category_name);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        Get_Items()
        Get_Categories()
    }, [])

    
    return (
        <div className={style.maindiv}>
            <div>
                <div className={`heading headingborder`} data-aos="fade-up" data-aos-offset="10">
                    Our Technologies
                </div>
                <div className={`p-lg-5 p-3 ${style.technologydiv}`}>
                    <div className={style.langmenudiv}>
                        <ul className={`nav nav-pills mb-3 justify-content-between ${style.langmenuwidth}`} id="pills-tab" role="tablist">
                            {
                                Categories.map((el, index) => {
                                    return (
                                        <li className="nav-item" role="presentation" key={index} data-aos="fade-up" data-aos-offset="0">
                                            <button
                                                className={`nav-link ${style.technologybtn} ${index === 0 || el.technology_category_name === selectedCategory ? 'active' : ''}`}
                                                id={`pills-${el.technology_category_name.toLowerCase()}-tab`}
                                                data-bs-toggle="pill"
                                                type="button"
                                                role="tab"
                                                aria-selected={index === 0 || el.technology_category_name === selectedCategory ? 'true' : 'false'}
                                                onClick={() => handleCategoryChange(el.technology_category_name)}
                                            >
                                                {el.technology_category_name}
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="tab-content text-center mt-md-5 mt-md-3 mt-1" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-webdevloper" role="tabpanel" aria-labelledby="pills-webdevloper-tab">
                            <div className={`d-flex justify-content-between ${style.langmenu}`}>
                                {Items.filter((item) => item.item_category.technology_category_name === selectedCategory).map((el, index) => (
                                    <div className={` ${style.langdiv}`} key={index} data-aos="fade-up" data-aos-offset="0">
                                        <div>
                                            <img src={el.item_img.url} className={style.imgitem} alt="ERROR 404" />
                                        </div>
                                        <div className='mt-3'>{el.item_name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OurTechnologies