import React from 'react'
import style from '../workflow.module.css'
import uiuxflowimage from '../../../assets/uiux/uiuxworkflow.png'

const UiUxWorkFlow = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our UI/UX Design Workflow
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        To design the most comprehensive and feature-rich apps feasible, we employ the most up-to-date quality standards, modern business strategies, and customized approaches. The following are the steps in our UI/UX Design Workflow:
                    </h1>
                </div>
                <div className={style.uiuxflowchart}>
                    <div className={style.uiuxbox1}>
                        <div className={style.subheading}>Crafting Design</div>
                    </div>
                    <div className={style.uiuxbox2}>
                        <div className={style.subheading}>Verification</div>
                    </div>
                    <div className={style.uiuxbox3}>
                        <div className={style.subheading}>Requirement Analysis</div>
                    </div>
                    <div className={style.uiuxbox4}>
                        <div className={style.subheading}>Implementation</div>
                    </div>
                    <div className={style.uiuxbox5}>
                        <div className={style.subheading}>Maintenance</div>
                    </div>
                    <div className='text-center'>
                        <img src={uiuxflowimage} className={style.flowimage} alt="ReactJs Flow" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UiUxWorkFlow