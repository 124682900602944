import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const ProductEngineearingServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our Product Engineering Services
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We provide the best Product Engineering Services that cover every phase of the software development cycle. Our experienced team also helps in the optimization of software engineering and developing strategies for software modernization that will improve the user experiences of your products.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Product Consulting</h1>
                            <p className={style.boxcontent}>We help you to find the weakness in your certification, hiring, and training procedures by providing all the information that is required to create prototypes and build new products.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Product Development</h1>
                            <p className={style.boxcontent}>To get the most effective product development services to get in touch with us because we follow Lean, MVP services and also provide testing facilities.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>Lifecycle Management</h1>
                            <p className={style.boxcontent}>We provide lifecycle management service that includes the branding & marketing and regular maintenance & support of the product.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductEngineearingServices