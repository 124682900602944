import React from 'react'
import style from '../workflow.module.css'
import reactjsflowimage from '../../../assets/reactjs/flowimage.png'

const ReactJsWorkFlow = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our ReactJS Development Workflow
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Explore the structured ReactJs development workflow that we follow to build high-quality web applications.
                    </h1>
                </div>
                <div className={style.flowchart}>
                        <div className={style.box1}>
                            <div className={style.subheading}>Design</div>
                            <div className={style.subcontent}>Our team of designers creates engaging and interactive user interfaces to ensure that your ReactJs web application offers a user-friendly experience and perfectly matches the requirements of your business.</div>
                        </div>
                        <div className={style.box2}>
                            <div className={style.subheading}>Deliver</div>
                            <div className={style.subcontent}>After testing and quality assurance, our developers deliver your ReactJS web application while ensuring that it performs seamlessly and is responsive across different types of platforms and devices.</div>
                        </div>
                        <div className={style.box3}>
                            <div className={style.subheading}>Build</div>
                            <div className={style.subcontent}>During this phase, our dedicated ReactJs developers write well-documented code to create dynamic user interfaces to ensure that your web application performs well and is responsive across devices.</div>
                        </div>
                        <div className={style.box4}>
                            <div className={style.subheading}>Discover</div>
                            <div className={style.subcontent}>ReactJs developers identify challenges, opportunities, and industry-specific best practices to create the best development strategy which ensures that you can have fully customized ReactJs development services.</div>
                        </div>
                        <div className='text-center'>
                            <img src={reactjsflowimage} className={style.flowimage} alt="ReactJs Flow" />
                        </div>
                    </div>
            </div>
        </>
    )
}

export default ReactJsWorkFlow