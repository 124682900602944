import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/android/androidservice1.png'
import service2 from '../../../assets/android/androidservice2.png'
import service3 from '../../../assets/android/androidservice3.png'
import service4 from '../../../assets/android/androidservice4.png'
import service5 from '../../../assets/android/androidservice5.png'
import service6 from '../../../assets/android/androidservice6.png'
import service7 from '../../../assets/android/androidservice7.png'
import service8 from '../../../assets/android/androidservice8.png'
import service9 from '../../../assets/android/androidservice9.png'

const AndroidServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder `}>
                    Services Our Android App Developers Can Offer
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        We are a leading Android App Development Company with a team of experienced Android app developers who are committed to turn your application ideas into a user-friendly solution.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row`}>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Custom Android App Development
                        </div>
                        <div className={style.contentfont}>
                            Our remote app developers build fully customized, interactive, and secure android applications according to your business needs that provide an exceptional user experience.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Wearable Applications
                        </div>
                        <div className={style.contentfont}>
                            Get our app developers who follow agile methodology to build futuristic and adaptive wearable android applications for different types of businesses.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            AR/VR Mobile Apps
                        </div>
                        <div className={style.contentfont}>
                            We provide the best service of Android app development so that you can have highly interactive and innovative Augmented & Virtual reality applications for the success of your business.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Blockchain Mobile Apps
                        </div>
                        <div className={style.contentfont}>
                            Hire app developers from Brain Binary Infotech who utilize their knowledge of blockchain technology to create different types of cryptocurrency and mining-related mobile apps.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            AI/Machine Learning Apps
                        </div>
                        <div className={style.contentfont}>
                            Our team of developers uses different types of machine learning algorithms to build intelligent and unique AI-based mobile applications that can associate with your business.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service6} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Android App Migration
                        </div>
                        <div className={style.contentfont}>
                            Want to migrate your application from any other platform to android? Our team of experts will make necessary changes in the code and migrate it to android.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service7} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Android App Porting
                        </div>
                        <div className={style.contentfont}>
                            We have top Android developers who can easily port your existing app to android so that your new application can get the attention of maximum users.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service8} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Android App Consultation
                        </div>
                        <div className={style.contentfont}>
                            Our programmers provide best-in-class consultation services for your android app idea and provide special facilities so that you can have all the information about the development process.
                        </div>
                    </div>
                </div>
                 <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.bottomsvg}>
                                <img src={service9} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Android Game Development
                        </div>
                        <div className={style.contentfont}>
                            Get our offshore Android app programmers who ensure that you can have a flawless running game that catches the attention of the users.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AndroidServices