import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { MdAddToPhotos } from "react-icons/md";
import { RiPlayListAddLine } from "react-icons/ri";
import { FaRegCircleQuestion } from "react-icons/fa6";
import LoderPageRound from '../Testing/LoderPageRound';
import { MdOutlineCases } from "react-icons/md";
import { FaRegPlusSquare } from "react-icons/fa";
import { HiMiniPencilSquare } from "react-icons/hi2";



function DashboardPage() {

  const [Blogs, setBlogs] = React.useState([]);
  const [GetQuotes, setGetQuotes] = React.useState([]);
  const [Categories, setCategories] = React.useState([]);
  const [Items, setItems] = React.useState([]);
  const [loader, Setloader] = useState(false);
  const [CaseStudy, setCaseStudy] = React.useState([]);
  const [CurrentJobOpenings, setCurrentJobOpenings] = React.useState([]);
  const [ApplyJobNow, setApplyJobNow] = React.useState([]);


  let DASH_BOARD = [
    {
      icon: <RiPlayListAddLine className='me-2 fs-4' />,
      title: "Our Technologies",
      total_count: Categories.length + " ( " + Items.length + " ) "
    },
    {
      icon: <FaRegCircleQuestion className='me-2 fs-4' />,
      title: " Get Quotes",
      total_count: GetQuotes.length
    },
    {
      icon: <MdAddToPhotos className='me-2 fs-4' />,
      title: "Blogs",
      total_count: Blogs.length
    },
    {
      icon: <MdOutlineCases className='me-2 fs-4' />,
      title: "Case Study",
      total_count: CaseStudy.length
    },
    {
      icon: <FaRegPlusSquare className='me-2 fs-4' />,
      title: "Current Openings",
      total_count: CurrentJobOpenings.length
    },
    {
      icon: <HiMiniPencilSquare className='me-2 fs-4' />,
      title: "Job Applied",
      total_count: ApplyJobNow.length
    },
  ]




  const Get_Blogs = () => {
    axios.get("https://api.brainbinaryinfotech.com/blogs/", {
      headers: { 'content-type': 'multipart/form-data' }
    })
      .then((res) => {
        setBlogs(res.data.BLOGS_DATA);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_Quotes = () => {
    axios.get("https://api.brainbinaryinfotech.com/getquote/")
      .then((res) => {
        setGetQuotes(res.data.GET_QUOTES);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_Items = () => {
    axios.get("https://api.brainbinaryinfotech.com/ourTechnologyitems/", {
      headers: { 'content-type': 'multipart/form-data' }
    })
      .then((res) => {
        setItems(res.data.TECH_ITEMS);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_CaseStudy = () => {
    axios.get("https://api.brainbinaryinfotech.com/casestudy/", {
      headers: { 'content-type': 'multipart/form-data' }
    })
      .then((res) => {
        setCaseStudy(res.data.CASE_STUDIES);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_Categories = () => {
    axios.get("https://api.brainbinaryinfotech.com/ourTechnologycategories/")
      .then((res) => {
        setCategories(res.data.TECH_CATEGORIES);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_CurrentJobOpenings = () => {
    axios.get("https://api.brainbinaryinfotech.com/careercurrentopenings/")
      .then((res) => {
        setCurrentJobOpenings(res.data.POSITIONS);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const Get_AppliedJobs = () => {
    axios.get("https://api.brainbinaryinfotech.com/applyjobnow/")
      .then((res) => {
        setApplyJobNow(res.data.JOB_DATA);
        Setloader(true)
      })
      .catch((err) => {
        console.log(err);
        Setloader(true)
      })
  }



  useEffect(() => {

    AOS.init();

    Get_Categories();
    Get_Items();
    Get_Quotes();
    Get_Blogs();
    Get_CaseStudy();
    Get_CurrentJobOpenings();
    Get_AppliedJobs();

  }, [])

  if (!loader) {
    return <LoderPageRound />
  }

  return (
    <div data-aos="fade-up" className='px-0'>
      <Row>
        <div className='mb-4'>
          <h4 style={{ color: "#0B618E", paddingLeft: "10px" }}> Dash Board </h4>
        </div>
      </Row>
      <Row>
        {
          DASH_BOARD.map((el, index) => {
            return (
              <Col className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-sm-12' key={index} style={{ padding: "10px" }}>
                <Card className='overflow-hidden rounded-4' style={{ backgroundColor: "#0B618E", boxShadow: "5px 5px 5px #bebebe, -5px -5px 10px #ffffff" }}>
                  <Card.Body>
                    <div className='text-center border-bottom'>
                      <h5 className='text-white mb-3 d-flex  align-items-center justify-content-center'>
                        {el.icon}
                        {el.title}
                      </h5>
                    </div>
                    <div className='text-center'>
                      <h5 className='text-white mt-3'> {el.total_count} </h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </div >
  )
}

export default DashboardPage;