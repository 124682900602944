import React from 'react'
import style from '../hiredevloper.module.css'
import linegroup from '../../../assets/linegroup1.png'

const HireFlutter = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                Hiring Flutter  Developers in 3 easy steps
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                    From us, you can easily hire Flutter developers who provide a seamless onboarding process to ensure that you can get a quick and efficient way to start your process.
                    </h1>
                </div>
                <div className='row'>
                    <div className="col"></div>
                    <div className="col-8 d-md-flex d-none">
                        <img src={linegroup} className={style.linegroup} alt="Line" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step1}>
                            <h1 className={style.boxheading}>Discuss your project requirements and budget with us</h1>
                            <p className={style.boxcontent}>In this phase, our Flutter app developers take their time to understand your unique needs and budget so that they can provide customized app development services.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step2}>
                            <h1 className={style.boxheading}>Appointment of the best Flutter Developers</h1>
                            <p className={style.boxcontent}>Once our developers understand your project requirements you can follow our selection process to hire Flutter developers who have all the necessary skills and experience.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-xl-3 p-lg-2 p-1">
                        <div className={style.step3}>
                            <h1 className={style.boxheading}>On-Time Delivery of the Project</h1>
                            <p className={style.boxcontent}>We have a team of experienced programmers who ensure that your Flutter app development project is progressing as planned and deliver it before the deadline as scheduled.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireFlutter