import React, { useState } from 'react';
import style from './experience.module.css';
import professinal from '../../assets/home/professional.png';
import frelancer from '../../assets/home/freelancer.png';
import experienceimg from '../../assets/home/experienceimg.png';
import completeproject from '../../assets/home/completeproject.png';
import yearexperience from '../../assets/home/yearexperience.png';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { Link } from "react-router-dom";


const Experience = () => {
  const [inView, setInView] = useState(false);
  return (
    <div className={style.maindiv}>
      <div className={`${style.heading} ${style.headingborder} mb-3`} data-aos="zoom-in" data-aos-offset="10" >
        We Have The Experience
      </div>
      <div className='position-relative'>
        <div className="row">
          <div data-aos="zoom-in" className="col-lg-6 col-12 p-2 py-lg-5  pe-xl-5 position-relative">
            <div className={style.roundimage}></div>
            <div className={style.squarimage}>
              <h1 className={style.number}>5+</h1>
              <p className={style.squarecontent}>Years of Experience</p>
            </div>
            <img src={experienceimg} alt="" className='h-100 w-100 p-lg-3 p-2' />
          </div>
          <div data-aos="zoom-in" className=" col-lg-6 col-12 p-sm-3 p-1 p-lg-5">
            <div className={`${style.content}`}>
              We have got the experience to make your dream come true, and these stats speak for themselves : we have 100+ clients worldwide in only five years, and we have worked on over 100+ projects, all of which have been finished successfully and on schedule, with 100% client satisfaction.
            </div>
            <div>
              <Link to="/contact-us" className={`btn ${style.contactusbtn}`}>
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className={`d-flex gap-3 ${style.detailexperience}`}>
          <div data-aos="zoom-in" className='d-flex flex-column align-items-center'>
            <div className={`${style.round}`}>
              <img src={professinal} alt="" className='h-100 w-100 p-lg-3 p-3' />
            </div>
            <div className={style.detailcontent}>Professional</div>
            <ScrollTrigger onEnter={() => setInView(true)} onExit={() => setInView(false)}>
              <div className={style.number}>
                {inView && <CountUp start={inView ? 0 : 20} end={20} duration={1} />}+
              </div>
            </ScrollTrigger>
          </div>
          <div data-aos="zoom-in" className='d-flex flex-column align-items-center'>
            <div className={`${style.round}`}>
              <img src={completeproject} alt="" className='h-100 w-100 p-lg-3 p-3' />
            </div>
            <div className={style.detailcontent}>Completed Projects</div>
            <ScrollTrigger onEnter={() => setInView(true)} onExit={() => setInView(false)}>
              <div className={style.number}>
                {inView && <CountUp start={inView ? 0 : 100} end={100} duration={1} />}+
              </div>
            </ScrollTrigger>
          </div>
          <div data-aos="zoom-in" className='d-flex flex-column align-items-center'>
            <div className={`${style.round}`}>
              <img src={frelancer} alt="" className='h-100 w-100 p-lg-3 p-3' />
            </div>
            <div className={style.detailcontent}> Running Projects </div>
            <ScrollTrigger onEnter={() => setInView(true)} onExit={() => setInView(false)}>
              <div className={style.number}>
                {inView && <CountUp start={inView ? 0 : 15} end={15} duration={1} />}+
              </div>
            </ScrollTrigger>
          </div>
          <div data-aos="zoom-in" className='d-flex flex-column align-items-center'>
            <div className={`${style.round}`}>
              <img src={yearexperience} alt="" className='h-100 w-100 p-lg-3 p-3' />
            </div>
            <div className={style.detailcontent}>Years of experience</div>
            <ScrollTrigger onEnter={() => setInView(true)} onExit={() => setInView(false)}>
              <div className={style.number}>
                {inView && <CountUp start={inView ? 0 : 5} end={5} duration={1} />}+
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience
