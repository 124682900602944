import React from 'react'
import style from '../service.module.css'
import service1 from '../../../assets/uiux/uiuxservices1.png'
import service2 from '../../../assets/uiux/uiuxservices2.png'
import service3 from '../../../assets/uiux/uiuxservices3.png'
import service4 from '../../../assets/uiux/uiuxservices4.png'
import service5 from '../../../assets/uiux/uiuxservices5.png'

const UiUxServices = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Our UI/UX Designs Service
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className={style.content}>
                        Brain Binary infotech provides various UI/UX design services to assist customers in increasing their brand recognition by producing intuitive, user-centric, and fully responsive UI/UX designs for their company app or website to help them stand out in a crowded market.
                    </h1>
                </div>
            </div>
            <div className={`${style.boxsection} row justify-content-center d-flex`}>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.uiuxbottomsvg}>
                                <img src={service1} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Sketch
                        </div>
                        <div className={style.contentfont}>
                            Our professional staff is well-versed in Sketch Software and creates impressive UI/UX designs tailored to your company's key goals.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.uiuxbottomsvg}>
                                <img src={service2} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Adobe Illustrator
                        </div>
                        <div className={style.contentfont}>
                            Our artists have years of expertise in creating artwork that is aesthetically gorgeous, appealing, and mind-blowing. To make your business stand out from the crowd, we make each illustration a memorable piece of it.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.uiuxbottomsvg}>
                                <img src={service3} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Adobe Photoshop
                        </div>
                        <div className={style.contentfont}>
                            Adobe Photoshop is an offline image editing application created by Adobe System Inc. Brain Binary Infotech has a team of creative artists that can edit pictures and develop unique UI/UX layouts.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.uiuxbottomsvg}>
                                <img src={service4} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Figma
                        </div>
                        <div className={style.contentfont}>
                            Figma is a prototype and vector graphics editor. Figma is a handy instrument because using it for live interaction saves time and stress, keeps teams on track, and keeps everything visible. Contact our staff right now for assistance with Figma.
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-4 p-xl-4 p-2`}>
                    <div className={`${style.sevicediv}`}>
                        <div className='d-flex justify-content-center'>
                            <div className={style.uiuxbottomsvg}>
                                <img src={service5} alt="Our core value" />
                            </div>
                        </div>
                        <div className={style.boldfont}>
                            Adobe XD
                        </div>
                        <div className={style.contentfont}>
                            Adobe XD is a user experience design toolkit for web and mobile apps that is vector-based. Using Adobe XD tools, our specialized designer develops the most up-to-date UI/UX solutions.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UiUxServices