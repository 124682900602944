import React from 'react'
import style from './careears.module.css'
import careearimage1 from '../../assets/careears/careearimage1.png'
// import ContactUs from '../../components/contactus/ContactUs'
import Haveanidea from '../../components/haveanidea/Haveanidea';
import roundspring from '../../assets/roundspring.png'
import joinourteam from '../../assets/careears/joinourteam.png'
import CurrentOpening from '../../components/currentopening/CurrentOpening'
import Careearinbb from '../../components/careearsinbb/Careearinbb'

const Careears = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`${style.rowpadding} row`}>
                    <div data-aos="zoom-in-right" className="col-lg-6 col-12 pe-xl-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.company}> Growth </span>
                            <span className={style.software}>At </span>
                            <span className={style.development}>Brain Binary Infotech </span>
                            <div className='mt-xl-4 mt-sm-3 mt-1'>
                                <p className={style.content}>Are you a developer, Designer, or passionate about providing solutions? If yes, then Brain BinaryInfotech  is the right place for your career growth. We are welcoming talented professionals from across the globe to work with us.</p>
                            </div>
                            <div className='mt-sm-5 mt-3 mb-3 mb-sm-0'>
                                <a href='#currentopening' className={`btn ${style.getquotebtn}`}>Check Current Openings</a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="" />
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-lg-6 col-12 ps-xl-5 text-end d-xl-inline d-flex justify-content-center">
                        <img src={careearimage1} alt="" className={style.careearimage} />
                    </div>
                </div>
                <div data-aos="fade-up" className={`heading headingborder mt-3 mt-md-4 mt-lg-5`}>
                    Join Our Team
                </div>
                <div className={`${style.rowpadding} row`}>
                    <div data-aos="fade-up" className="col-lg-6 col-12 pe-xl-3 d-xl-inline d-flex justify-content-center">
                        <img src={joinourteam} className={style.careearimage} alt="About Us" />
                    </div>
                    <div data-aos="fade-up" className="col-lg-6 col-12 px-xl-5 pt-xl-5 px-sm-3 mt-4 mt-md-0">
                        <p className={style.contentjpinourteam}>Brain Binary Infotech is based in Surat, Gujarat. A city that is perfect for software nearshoring because of its location and time zone.  We are well-known for providing high-quality, dependable, and timely delivery of IT services eith tailor-made requirements for all facets of the business development to a vast collection of clientele. we have professional development with extensive industry knowledge and abilities to analyze your company’s requirements and build the best solution for you.</p>
                    </div>
                </div>
                <CurrentOpening />
                <Careearinbb />
                <Haveanidea />
                {/* <ContactUs /> */}
            </div>
        </>
    )
}

export default Careears
