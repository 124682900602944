import React, { useState } from 'react'
import style from './currentopening.module.css'
import { FaRegCheckCircle } from "react-icons/fa";
import roundspringimg from '../../assets/rightspringimage.png'
import ApplyForJob from '../../components/applyforjob/ApplyForJob'
import axios from 'axios';
import { useEffect } from 'react';


const CurrentOpening = () => {

    const [showModal, setShowModal] = useState(false);
    const [CurrentPosition, setCurrentPosition] = React.useState([]);
    const [selectedJobTitle, setSelectedJobTitle] = useState(null);

    const Get_CurrentPosition = () => {
        axios.get("https://api.brainbinaryinfotech.com/careercurrentopenings/")
            .then((res) => {
                setCurrentPosition(res.data.POSITIONS);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const ModelIdHandler = (JobTitle) => {
        setShowModal(true);
        setSelectedJobTitle(JobTitle);
    }

    useEffect(() => {
        Get_CurrentPosition();
    }, [])

    return (
        <>
            <div className={style.maindiv} id='currentopening'>
                <div className={`heading headingborder`} data-aos="fade-up">
                    Current Openings
                </div>
                <div className={style.rowpadding}>
                    <div className={style.springimage}>
                        <img src={roundspringimg} alt="Rounded Spring" className='w-100 h-100' />
                    </div>
                    <div className={`accordion`} id="accordionExample">

                        {
                            CurrentPosition.map((el, index) => {
                                return (
                                    <div data-aos="fade-up" key={index} className={`accordion-item border-0 mb-3  pt-md-3 px-sm-5 px-md-4 px-sm-3 pt-2  px-2 ${style.accordian}`}>
                                        <h2 className={`accordion-header  pb-sm-4 pb-2`} id={`heading${index}`}>
                                            <div className={style.accordianheading}>{el.JobTitle}</div>
                                            <div className='row'>
                                                <div className="col-md-3 col-sm-4 mb-sm-0 mb-3 col-4 d-flex">
                                                    <div className='me-2'>
                                                        <svg className={style.svg} width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.7158 31.5C18.3647 31.5 27.7158 19.2137 27.7158 12.5556C27.7158 5.89746 22.3432 0.5 15.7158 0.5C9.0884 0.5 3.71582 5.89746 3.71582 12.5556C3.71582 19.2137 13.067 31.5 15.7158 31.5ZM15.7158 17.7222C18.5561 17.7222 20.8586 15.409 20.8586 12.5556C20.8586 9.70209 18.5561 7.38889 15.7158 7.38889C12.8755 7.38889 10.573 9.70209 10.573 12.5556C10.573 15.409 12.8755 17.7222 15.7158 17.7222Z" fill="url(#paint0_linear_91_11945)" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear_91_11945" x1="15.7158" y1="0.5" x2="15.7158" y2="31.5" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div className=''>
                                                        <p className={style.hirecontentsmall}>Location</p>
                                                        <p className={style.hiresmall}>{el.Location}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-3 mb-sm-0 mb-3 col-4 d-flex">
                                                    <div className='me-2'>
                                                        <svg className={style.svg} width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_91_11951)">
                                                                <path d="M27.6328 22.6667V26.9167M27.6328 26.9167V31.1667M27.6328 26.9167H23.3828M27.6328 26.9167H31.8828" stroke="url(#paint0_linear_91_11951)" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.7155 2.83325C9.89125 2.83325 3.54883 9.17567 3.54883 16.9999C3.54883 24.8242 9.89125 31.1666 17.7155 31.1666C18.5471 31.1666 19.3602 31.0958 20.1522 30.9583C19.4804 29.7172 19.1298 28.3278 19.1322 26.9166C19.1322 24.4431 20.189 22.2161 21.8748 20.6634L18.6477 19.0498C17.9419 18.6968 17.3483 18.1543 16.9335 17.483C16.5186 16.8116 16.2989 16.0381 16.2988 15.2489V9.91659C16.2988 9.54086 16.4481 9.18053 16.7138 8.91485C16.9794 8.64917 17.3398 8.49992 17.7155 8.49992C18.0912 8.49992 18.4516 8.64917 18.7172 8.91485C18.9829 9.18053 19.1322 9.54086 19.1322 9.91659V15.2489C19.1323 15.5119 19.2057 15.7697 19.344 15.9934C19.4824 16.2171 19.6803 16.3978 19.9156 16.5154L24.0154 18.5668C24.2265 18.6716 24.3993 18.8218 24.5282 19.0003C25.5172 18.613 26.5701 18.4151 27.6322 18.4166C29.0956 18.4166 30.4712 18.7849 31.6739 19.4366C31.8124 18.6318 31.8821 17.8166 31.8822 16.9999C31.8822 9.17567 25.5397 2.83325 17.7155 2.83325Z" fill="url(#paint1_linear_91_11951)" />
                                                            </g>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_91_11951" x1="27.6328" y1="22.6667" x2="27.6328" y2="31.1667" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint1_linear_91_11951" x1="17.7155" y1="2.83325" x2="17.7155" y2="31.1666" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <clipPath id="clip0_91_11951">
                                                                    <rect width="34" height="34" fill="white" transform="translate(0.71582)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                    <div className=''>
                                                        <p className={style.hirecontentsmall}>Experience</p>
                                                        <p className={style.hiresmall}>{el.Experience}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-2 col-4 d-flex">
                                                    <div className='me-2'>
                                                        <svg className={style.svg} width="35" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M23.8437 6.36887C23.8437 9.64698 21.1863 12.3044 17.9082 12.3044C14.6301 12.3044 11.9727 9.64698 11.9727 6.36887C11.9727 3.09077 14.6301 0.43335 17.9082 0.43335C21.1863 0.43335 23.8437 3.09077 23.8437 6.36887Z" fill="url(#paint0_linear_91_11959)" />
                                                            <path d="M28.0828 21.6316C28.0828 24.9097 23.5272 27.5671 17.9076 27.5671C12.288 27.5671 7.73242 24.9097 7.73242 21.6316C7.73242 18.3535 12.288 15.696 17.9076 15.696C23.5272 15.696 28.0828 18.3535 28.0828 21.6316Z" fill="url(#paint1_linear_91_11959)" />
                                                            <path d="M9.63515 2.12915C9.9361 2.12915 10.2299 2.15868 10.5136 2.21489C9.82244 3.44253 9.42817 4.85963 9.42817 6.36881C9.42817 7.84124 9.80348 9.22604 10.4636 10.4327C10.1955 10.4827 9.91848 10.5089 9.63515 10.5089C7.23649 10.5089 5.29199 8.63299 5.29199 6.319C5.29199 4.00501 7.23649 2.12915 9.63515 2.12915Z" fill="url(#paint2_linear_91_11959)" />
                                                            <path d="M6.79544 25.8477C5.83232 24.6964 5.18888 23.2836 5.18888 21.6318C5.18888 20.0306 5.79357 18.6539 6.708 17.5222C3.47792 17.7729 0.949219 19.5394 0.949219 21.6817C0.949219 23.8439 3.52235 25.6235 6.79544 25.8477Z" fill="url(#paint3_linear_91_11959)" />
                                                            <path d="M26.3871 6.36881C26.3871 7.84124 26.0118 9.22604 25.3516 10.4327C25.6199 10.4827 25.8968 10.5089 26.1802 10.5089C28.5788 10.5089 30.5233 8.63299 30.5233 6.319C30.5233 4.00501 28.5788 2.12915 26.1802 2.12915C25.8792 2.12915 25.5855 2.15868 25.3018 2.21489C25.9928 3.44253 26.3871 4.85963 26.3871 6.36881Z" fill="url(#paint4_linear_91_11959)" />
                                                            <path d="M29.0195 25.8477C32.2925 25.6235 34.8657 23.8439 34.8657 21.6817C34.8657 19.5394 32.337 17.7729 29.1069 17.5222C30.0213 18.6539 30.626 20.0306 30.626 21.6318C30.626 23.2836 29.9826 24.6964 29.0195 25.8477Z" fill="url(#paint5_linear_91_11959)" />
                                                            <defs>
                                                                <linearGradient id="paint0_linear_91_11959" x1="17.9082" y1="0.43335" x2="17.9082" y2="12.3044" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint1_linear_91_11959" x1="17.9076" y1="15.696" x2="17.9076" y2="27.5671" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint2_linear_91_11959" x1="7.9028" y1="2.12915" x2="7.9028" y2="10.5089" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint3_linear_91_11959" x1="3.87233" y1="17.5222" x2="3.87233" y2="25.8477" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint4_linear_91_11959" x1="27.9125" y1="2.12915" x2="27.9125" y2="10.5089" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                                <linearGradient id="paint5_linear_91_11959" x1="31.9426" y1="17.5222" x2="31.9426" y2="25.8477" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#68B1D2" />
                                                                    <stop offset="1" stopColor="#0B618E" />
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div className=''>
                                                        <p className={style.hirecontentsmall}>Position</p>
                                                        <p className={style.hiresmall}>{el.Position}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-sm-3 col-6 d-sm-flex justify-content-end">
                                                    <button className={`btn ${style.detailbtn}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id={`collapse${index}`} className={`accordion-collapse collapse ${style.collapse}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-0">
                                                <p className={style.collapscontent}>
                                                    {el.CurrentOpeningsDescription}
                                                </p>
                                                <p className={style.collapscontent1}>Skills and Ability required for the This Position .</p>
                                                <div className="row">
                                                    {
                                                        el.SkillsAbilitiesRequired.map((skills, index) => {
                                                            return (
                                                                <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={index}>
                                                                    <div className='d-flex'><FaRegCheckCircle className={`${style.icons} me-2 fs-5`} /> &nbsp;
                                                                        <p className={style.collapscontent2}> {skills} </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className='text-end pb-4'>
                                                <button className={`${style.applybtn} btn`} onClick={() => {
                                                    ModelIdHandler(el.JobTitle)
                                                }}>Apply Now</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <ApplyForJob showModal={showModal} closeModal={() => setShowModal(false)} selectedJobTitle={selectedJobTitle} />
            </div >
        </>
    )
}


export default CurrentOpening;