import React, { useState } from 'react'
import style from './hiredevlopers.module.css'
import { Button, Form } from 'react-bootstrap';

const HireDevlopers = () => {
    const [errors, setErrors] = useState({ name: '', email: '', phone: '', message: '' });
    const [form, setForm] = useState({ name: '', email: '', phone: '', message: '' });

    // const validateForm = () => {
    //     const { name, email, phone, message } = form;
    //     const newErrors = {};

    //     if (!name || name === "") {
    //         newErrors.name = "Please Enter Name";
    //     }

    //     if (!email || email === "") {
    //         newErrors.email = "Please Enter Email";
    //     } else if (!/\S+@\S+\.\S+/.test(email)) {
    //         newErrors.email = "Please Enter Valid Email";
    //     }

    //     if (!phone || phone === "") {
    //         newErrors.phone = "Please Enter Phone Number";
    //     } else if (!/^\d{10}$/.test(phone)) {
    //         newErrors.phone = "Please Enter Valid Phone Number";
    //     }

    //     if (!message || message === "") {
    //         newErrors.message = "Please Enter Message";
    //     }

    //     return newErrors;
    // };

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const handleOnSubmit = () => {

    }

    return (
        <>
            <div className={style.maindiv}>
                <h1 className={style.contactheading}>
                    Reduce The Project Costs By 30%
                </h1>
                <h1 className={style.subcontentheading}>With Our Elite Designers, Developers, and Testers</h1>
                <div className=''>
                    <Form>
                        <Form.Group controlId='name' className='d-flex justify-content-center'>
                            <Form.Control
                                type="text"
                                placeholder='Full Name'
                                className={`${style.inputfield} form-control mt-xl-4 mt-3 p-xl-3 px-3 py-2`}
                                value={form.name}
                                onChange={(e) => setField("name", e.target.value)}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='email' className='d-flex justify-content-center'>
                            <Form.Control
                                type="text"
                                placeholder='Email Address'
                                className={`${style.inputfield} form-control  mt-xl-4 mt-3 p-xl-3 px-3 py-2`}
                                value={form.email}
                                onChange={(e) => setField("email", e.target.value)}
                                isInvalid={!!errors.categoryname}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='phone' className='d-flex justify-content-center'>
                            <Form.Control
                                type="text"
                                placeholder='Contact Number'
                                className={`${style.inputfield} form-control  mt-xl-4 mt-3 p-xl-3 px-3 py-2`}
                                value={form.phone}
                                onChange={(e) => setField("phone", e.target.value)}
                                isInvalid={!!errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='message' className='d-flex justify-content-center'>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Message'
                                className={`${style.inputfield} form-control  mt-xl-4 mt-3 p-xl-3 px-3 py-2`}
                                value={form.message}
                                onChange={(e) => setField("message", e.target.value)}
                                isInvalid={!!errors.message}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.message && <div className="text-danger">{errors.message}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className=''>
                            <div className={style.button}>
                                <Button className={`btn ${style.submitbtn} mt-4 px-4`} onClick={handleOnSubmit}>
                                    Hire Developers Now
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default HireDevlopers
