import React from 'react'
import style from './itStaff.module.css'
import roundspring from '../../assets/roundspring.png'
import ContactUs from '../../components/contactus/ContactUs'
import itstaffimage1 from '../../assets/itstaff/itstaffimage1.png'
import itstaffimage2 from '../../assets/itstaff/itstaffimage2.png'
import GetFreeConsultancy from '../../components/getfreeconsultancy/GetFreeConsultancy'
import Experience from '../../components/expireance/Experience'
import WordsFromClientele from '../../components/wordsfromClientele/WordsFromClientele'
import Agmentedteam from '../../components/agmentedteam/Agmentedteam'
import ItScaleUpTeam from '../../components/services/itscaleupteam/itscaleteamteam'
import ItStaffAugmentationServices from '../../components/numberbackground/itstaffaugmentationservices/ItStaffAugmentationServices'
import ItStaffProcess from '../../components/workflow/itstaffprocess/ItStaffProcess'
import IndustryServe from '../../components/industryserve/IndustryServe'

const ItStaff = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`row ${style.rowpadding}`}>
                    <div className="col-lg-6 col-12 pe-xl-3 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <span className={style.development}>IT </span>
                            <span className={style.software}>Staff </span>
                            <span className={style.company}>Augmentation</span>
                            <div className='mt-md-4 mt-2'>
                                <p className={style.content}>We have a team of highly experienced software developers and QA testers that provide various IT staff augmentation services so that you can leverage their expertise in your project. Moreover, Brain Binary Infotech is one of the leading staff augmentation firms that are well-versed with the latest-trends, and technologies to ensure that software development projects are executed efficiently.
                                </p>
                            </div>
                            <div>
                                <a href='#getfreeconsultancy' className={`btn mt-xl-5 mt-3 ${style.getquotebtn}`}>Book a Free Consultation </a>
                            </div>
                        </div>
                        <div className={style.springimage}>
                            <img src={roundspring} alt="Rounded Spring" className='h-100 w-100' />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 ps-xl-5 mt-lg-0 mt-md-5 mt-3 text-end d-xl-inline d-flex justify-content-center position-relative">
                        <img src={itstaffimage1} className={style.itstaffimage} alt="Web Devlopment" />
                    </div>
                </div>
                <div className={`row ${style.rowpadding}`}>
                    <div className=''>
                        <h1 className={`${style.heading} ${style.headingborder}`}>Upscale and Up Skill Your Enterprise with our Staff Augmentation Services</h1>
                    </div>
                    <div className="col-lg-6 col-12 pe-3  mt-lg-5 mt-sm-3 mt-2 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>
                            <div className='mt-md-4 mt-2'>
                                <p className={style.content}>
                                    Get our different types of IT staff augmentation services in this modern time to ensure that your business can stay ahead of its competitors. We are a leading IT staffing company with a team of proficient software developers and agile scrum masters that create highly responsive, scalable, and cost-efficient solutions that can satisfy all the requirements of your business.
                                </p>
                                <p className={style.content}>
                                    Our certified IT professionals easily coordinate with your in-house team of developers to make sure that your enterprise can get the best solution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12  mt-lg-5 mt-3 text-end d-xl-inline d-flex justify-content-center position-relative">
                        <img src={itstaffimage2} className={style.itstaffimage} alt="Web Devlopment" />
                    </div>
                </div>
                <Agmentedteam />
                <ItScaleUpTeam />
                <Experience />
                <GetFreeConsultancy />
                <ItStaffAugmentationServices />
                <IndustryServe />
                <ItStaffProcess />
                <WordsFromClientele />
                <ContactUs />
            </div >
        </>
    )
}

export default ItStaff