import React from 'react'
import style from './agmentedteam.module.css'
import agmentedteam1 from '../../assets/itstaff/agmentedteam1.png'
import agmentedteam2 from '../../assets/itstaff/agmentedteam2.png'
import agmentedteam3 from '../../assets/itstaff/agmentedteam3.png'
import agmentedteam4 from '../../assets/itstaff/agmentedteam4.png'
import agmentedteam5 from '../../assets/itstaff/agmentedteam5.png'
import agmentedteam6 from '../../assets/itstaff/agmentedteam6.png'
import agmentedteam7 from '../../assets/itstaff/agmentedteam7.png'
import agmentedteam8 from '../../assets/itstaff/agmentedteam8.png'
import agmentedteam9 from '../../assets/itstaff/agmentedteam9.png'

const Agmentedteam = () => {
    return (
        <>
            <div className={style.maindiv}>
                <div className={`heading headingborder`}>
                    Augmented Teams
                </div>
                <div className='d-flex justify-content-center pb-lg-4'>
                    <h1 className={style.content}>
                        Our augmented team of developers enhances the performance of your organization. Consult with our different types of teams who are available for hire at reduced costs and work according to your business objectives. c
                    </h1>
                </div>
                <div className='overflow-hidden'>
                    <div className={`row mt-lg-5 mt-3 ${style.borderrow}`}>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam1} alt="" />
                            <div className={style.boldfont}>
                                Enterprise Agile
                            </div>
                            <div className={style.contentfont}>
                                We empower your enterprise to adapt quickly according to market changes and continuously deliver the best application modernization solutions to unlock the potential for innovation and efficiency in this competitive world.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam2} alt="" />
                            <div className={style.boldfont}>
                                Low-code/No-code
                            </div>
                            <div className={style.contentfont}>
                                Our low-code/no-code solutions simplify the modern app development process at a reduced cost to improve your online presence without compromising the quality and scalability of the application.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam3} alt="" />
                            <div className={style.boldfont}>
                                Mainframe Modernization & Migration
                            </div>
                            <div className={style.contentfont}>
                                Our low-code/no-code solutions simplify the modern app development process at a reduced cost to improve your online presence without compromising the quality and scalability of the application.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam4} alt="" />
                            <div className={style.boldfont}>
                                NextGen DevOps
                            </div>
                            <div className={style.contentfont}>
                                Our NextGen DevOps utilizes cutting-edge tools and the latest methodologies to deliver high-quality applications consistently. Streamline the collaboration with our developers who automate various workflows to build the app before the deadline.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam5} alt="" />
                            <div className={style.boldfont}>
                                Open Source
                            </div>
                            <div className={style.contentfont}>
                                Utilize the full potential of open-source technologies to modernize your applications and our experts build open-source app modernization solutions that are innovative, flexible, and cost-efficient.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam6} alt="" />
                            <div className={style.boldfont}>
                                App Security and Event Management
                            </div>
                            <div className={style.contentfont}>
                                We offer robust app security and event management services by integrating data protection features into the app. Our programmers follow the best coding standards that help to Detect, respond, and relieve the risks effectively
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam7} alt="" />
                            <div className={style.boldfont}>
                                NextGen DevOps
                            </div>
                            <div className={style.contentfont}>
                                Our NextGen DevOps utilizes cutting-edge tools and the latest methodologies to deliver high-quality applications consistently. Streamline the collaboration with our developers who automate various workflows to build the app before the deadline.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>

                            <img src={agmentedteam8} alt="" />
                            <div className={style.boldfont}>
                                Open Source
                            </div>
                            <div className={style.contentfont}>
                                Utilize the full potential of open-source technologies to modernize your applications and our experts build open-source app modernization solutions that are innovative, flexible, and cost-efficient.
                            </div>
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${style.sevicediv}`}>
                            <img src={agmentedteam9} alt="" />
                            <div className={style.boldfont}>
                                App Security and Event Management
                            </div>
                            <div className={style.contentfont}>
                                We offer robust app security and event management services by integrating data protection features into the app. Our programmers follow the best coding standards that help to Detect, respond, and relieve the risks effectively
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Agmentedteam